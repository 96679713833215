import styled, { css, keyframes } from "styled-components";

const isShowToUp = keyframes`
  0% {
    transform: translateY(200%);
    opacity: 0
  }

  50% {
    transform: translateY(100%);
    opacity: 0.2
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const isShowToUpCss = css`
  animation: ${isShowToUp} 0.8s linear 0.8s;
  animation-fill-mode: forwards;
`;

export const TransformShowBlock = styled.div`
  /* height: ${(props) => (props.height ? props.height : "56")}px; */
  display: flex;
  justify-content: center;
  margin-top: 314px;
  align-items: flex-end;
  flex-direction: column;
  transform: translateY(200%);
  opacity: 0;

  animation-play-state: ${({ isAnimated }) =>
    isAnimated ? "running" : "paused"};

  ${({ isAnimated }) => isAnimated && isShowToUpCss}
`;
