import React from "react";
import { Route } from "react-router-dom";
// import Intro from "./components/Intro";
// import Hero from "./components/Hero";
// import Integrations from "./components/Integrations";
// import Quotes from "./components/Quotes";
// import Advantage from "./components/Advantage";
// import Internals from "./components/Internals";
// import Awesome from "./components/Awesome";
// import AdminPanel from "./components/AdminPanel";
// import NumberChapter from "./components/NumberChapter";
// import Technologies from "./components/Technologies";
// import HoldOn from "./components/HoldOn";
// import Business from "./components/Business";
// import Peculiarities from "./components/Peculiarities";
// import Reference from "./components/Reference";
// import Footer from "./components/Footer";
// import Header from "./components/Header";
import Container from "./views/Container";

const App = () => {
  return (
    <>
      <Route exact path={"/"} component={Container} />
      {/* <Route exact path={"/Hero"} component={Hero} />
      <Route exact path={"/Integrations"} component={Integrations} />
      <Route exact path={"/Quotes"} component={Quotes} />
      <Route exact path={"/Advantage"} component={Advantage} />
      <Route exact path={"/Internals"} component={Internals} />
      <Route exact path={"/Awesome"} component={Awesome} />
      <Route exact path={"/AdminPanel"} component={AdminPanel} />
      <Route exact path={"/NumberChapter"} component={NumberChapter} />
      <Route exact path={"/Technologies"} component={Technologies} />
      <Route exact path={"/HoldOn"} component={HoldOn} />
      <Route exact path={"/Business"} component={Business} /> */}
      {/* <Header /> */}
      {/* <Container /> */}
      {/* <Hero /> */}
      {/* <Integrations />
      <Quotes />
      <Advantage />
      <Internals />
      <Awesome />
      <AdminPanel />
      <NumberChapter />
      <Technologies />
      <HoldOn />
      <Business />
      <Peculiarities />
      <Reference />
      <Footer /> */}
    </>
  );
};

export default App;
