import styled from "styled-components";

export const SectionIntegrationsContainer = styled.div`
  display: flex;
  margin: auto auto 55px;
`;

export const SectionIntegrationMainTextWrapper = styled.div`
  display: flex;
`;
