import styled, { css, keyframes } from "styled-components";

const isTransformToUp = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const isShowToUp = keyframes`
  0% {
    transform: translateY(200%);
    opacity: 0
  }

  50% {
    transform: translateY(100%);
    opacity: 0.2
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const isHideToUp = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1
  }

  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
`;

const isShowAnimation = keyframes`
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
`;

export const TransformTextWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(props) => (props.jc ? props.jc : "")};
  width: ${(props) => (props.widthBlock ? props.widthBlock : "")}px;
  margin: ${(props) => (props.center ? props.center : "")};
  height: ${({ height }) => (height ? `${height}px` : "")};
  @media (min-width: 768px) {
    /* display: flex;
    flex-wrap: nowrap;
    justify-content: center; */
    justify-content: center;
    width: ${(props) => (props.widthBlock ? props.widthBlock : "")}px;
  }
  @media (min-width: 1366px) {
    /* display: flex;
    flex-wrap: nowrap;
    justify-content: center; */
    justify-content: center;
    width: ${(props) => (props.widthBlock ? props.widthBlock : "")}px;
  }
`;

const isTransformToUpCss = css`
  animation: ${isTransformToUp} 0.6s ease;
  animation-fill-mode: forwards;
`;

export const TransformText = styled.div`
  overflow: hidden;
  display: flex;
  margin: ${({ center }) => (center ? "0 auto" : "")};
  /* margin-right: ${({ center }) => (center ? "" : "15px")}; */

  &:last-child {
    margin-right: 0;
  }

  h1,
  h2,
  p,
  span {
    transform: translateY(100%);
    opacity: 0;
    font-size: ${(props) => (props.fontSize ? props.fontSize : "")}px;
    animation-play-state: ${({ isAnimated }) =>
      isAnimated ? "running" : "paused"};

    ${({ isAnimated }) => isAnimated && isTransformToUpCss}

    ${({ delay }) =>
      delay &&
      `
      animation-delay: ${delay}s;
      `};
  }
  @media (min-width: 1336px) {
    margin: 0;
    font-size: ${(props) => (props.fontSize ? props.fontSize : "")}px;
  }
`;

const isShowToUpCss = css`
  animation: ${isShowToUp} 0.6s linear 2.2s;
  animation-fill-mode: forwards;
`;

export const TransformShowText = styled.div`
  height: ${(props) => (props.height ? props.height : "56")}px;
  display: flex;
  justify-content: center;
  transform: translateY(200%);
  opacity: 0;
  flex-direction: ${({ column }) => (column ? "column" : "row")};

  animation-play-state: ${({ isAnimated }) =>
    isAnimated ? "running" : "paused"};

  ${({ isAnimated }) => isAnimated && isShowToUpCss}
`;

export const AnimationWrapperForHide = styled.div`
  transform: translateY(0);
  opacity: 1;
  transition: opacity 0.6s linear 1s;

  ${({ isHide, isAnimated }) =>
    isHide &&
    isAnimated &&
    css`
      animation: ${isHideToUp} 0.6s linear;
      animation-fill-mode: forwards;
      opacity: 0;
      transition: opacity 0.6s;
    `}
`;

export const ShowStyle = styled.div`
  opacity: 0;

  ${({ isAnimated, delay }) =>
    isAnimated &&
    css`
      animation: ${isShowAnimation} 1s linear ${delay || 0}s;
      animation-fill-mode: forwards;
    `}
`;

export const TransformLeftToRight = styled.div`
  position: relative;
  background-color: var(--mainBlack);
  padding: 15px;
  border-radius: 10px;
  margin-right: 16px;
  margin-bottom: 24px;
  color: #fff;
  width: 0;
  height: 55px;
  transition: width 0.6s linear 0.1s, opacity 1s linear;
  opacity: 0;

  &:nth-child(1) {
    margin-right: 24px;
  }
  &:nth-child(3) {
    margin-right: 27px;
  }
  & > div {
    position: absolute;
    white-space: nowrap;
    display: flex;
    align-items: baseline;
    @media (min-width: 1366px) {
      margin-left: 10px;
      margin-top: 3px;
    }
  }

  @media (min-width: 768px) {
    padding: 32px;
    height: 114px;
  }

  @media (min-width: 1366px) {
    height: 122px;
  }

  @media (min-width: 1920px) {
    height: 138px;
  }

  ${({ isAnimated, currentWidth }) =>
    isAnimated &&
    currentWidth &&
    css`
      width: ${currentWidth + 34}px;
      opacity: 1;

      @media (min-width: 768px) {
        width: ${currentWidth + 64}px;
      }
      @media (min-width: 1366px) {
        width: ${currentWidth + 94}px;
      }
    `}

  ${({ isAnimated, isHide }) =>
    isAnimated &&
    isHide &&
    css`
      width: 0 !important;
      opacity: 0;
      transition: width 1s linear, opacity 0.3s linear 0.4s;

      & > div {
        opacity: 0;
        transition: opacity 0.1s linear;
      }
    `}

  ${({ isAnimated, isSemiHide }) =>
    isAnimated &&
    isSemiHide &&
    css`
      & > div {
        opacity: 0;
        transition: opacity 0.4s linear;
      }
    `}
`;
