import { useLottie } from "lottie-react";
import { Container, HoldOnWrapper } from "../style";
import React from "react";
import { useState, useEffect, useContext } from "react";
import { AnimationWrapperForHide } from "../styleAnimation";
import { Context } from "../views/Container";
import { Global2 } from "../views/Sections/SectionAwesome/style";
// import { useMediaQuery } from "react-responsive";
import holdOnAnimation from "../animation/hold_on.json";

const HoldOn = () => {
  const context = useContext(Context);
  const { isLeaving, scrollPointer, direction } = context;

  const [isReadyAnimationStart, setIsReadyAnimationStart] = useState(false);
  const [animationNumber, setAnimationNumber] = useState(null);

  useEffect(() => {
    if (scrollPointer !== null && scrollPointer === 16) {
      setTimeout(() => {
        setIsReadyAnimationStart(true);
      }, 0);
    }
  }, [scrollPointer]);

  useEffect(() => {
    if (scrollPointer === 16) {
      setAnimationNumber("Section-2");
    }
    if (
      (scrollPointer === 16 && isLeaving === "finish" && direction !== 0) ||
      scrollPointer !== 16
    ) {
      setAnimationNumber("leaveSection-2");
    }
  }, [scrollPointer, isLeaving, direction]);

  const options = {
    animationData: holdOnAnimation,
    loop: true,
    autoplay: true,
  };

  const { View } = useLottie(options);

  return (
    <Container>
      <AnimationWrapperForHide>
        <Global2 isShow={isReadyAnimationStart} isAnimated={animationNumber}>
          <HoldOnWrapper>{View}</HoldOnWrapper>
        </Global2>
      </AnimationWrapperForHide>
    </Container>
  );
};

export default HoldOn;
