import styled from "styled-components";
import SlidingPane from "react-sliding-pane";
import { Slide } from "pure-react-carousel";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import TextareaAutosize from "react-textarea-autosize";
import { keyframes, css } from "styled-components";

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  padding: 36px 16px;
  @media (width: 768px) {
    padding: 36px 32px;
  }
  @media (min-width: 1366px) {
    padding: 64px 32px;
  }
  @media (min-width: 1920px) {
    padding: 122px 128px;
  }
`;

export const TextWrapper = styled.div`
  text-align: ${(props) => (props.tl ? props.tl : "center")};
  height: ${(props) => (props.hg ? props.hg : "")}px;
  margin-bottom: ${(props) => (props.mb ? props.mb : "")}px;

  @media (min-width: 1366px) {
    margin-bottom: ${(props) => (props.mt ? props.mt : "32")}px;
    margin-bottom: ${(props) => (props.mb ? props.mb : "")}px;
  }
`;

export const TextWrapperAdminPanel = styled(TextWrapper)`
  @media (min-width: 1366px) {
    width: 710px;
    text-align: start;
    margin-right: 34px;
    margin-top: 120px;
  }
  @media (min-width: 1920px) {
    width: 822px;
    text-align: start;
  }
`;

export const ContentWrapperAdminPanel = styled.div`
  @media (min-width: 1366px) {
    display: flex;
    height: 632px;
    align-items: center;
  }
`;

export const IntroLogoWrapper = styled.div`
  margin-bottom: 24px;

  & > svg {
    height: 52px;
    width: auto;
  }

  @media (min-width: 768px) {
    margin-bottom: 64px;
    & > svg {
      height: 64px;
      width: 262px;
    }
  }
  @media (min-width: 1366px) {
    & > svg {
      height: 67px;
      width: 262px;
    }
  }
`;

export const HideMenuInnerWrapper = styled.div`
  position: absolute;
  width: 220px;
  /* display: none; */
  visibility: hidden;
  border: 1px;
  text-align: initial;
  border-radius: 5px;
  padding-top: 24px;
  &:before {
    content: "";
    height: 2px;
    width: 0px;
    background: #0062eb;
    transform: rotate(90deg);
    top: 65px;
    position: absolute;
    left: -60px;
    border-radius: 40px;
    transition: all 0.6s ease;
  }
`;

export const InnerMenuLink = styled.p`
  color: #7b949d;
  cursor: pointer;
  padding-left: 20px;

  &:hover {
    color: #101820;
  }
  &:nth-child(2) {
    margin: 24px 0px;
  }
`;

export const HideMenuWrapper = styled.div`
  position: relative;
  &:hover ${HideMenuInnerWrapper} {
    display: block;
    visibility: visible;
    &:before {
      background: #0062eb;
      width: 101px;
      height: 2px;
    }
  }
`;

export const HeaderLinkTitle = styled.p`
  cursor: pointer;
  font-size: 20px;
  color: var(--grayDark);
  transition: ease 0.3s;
  &:hover {
    color: #101820;
  }
`;

export const HidenHeaderMenu = styled.div`
  position: absolute;
  height: 38px;
  top: 0;
  right: 0;
  width: 100%;
  overflow: hidden;
  background: inherit;
  transition: height 0.7s, background 0.7s;
`;

export const HeaderStyled = styled.div`
  padding: 160px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0px;
  z-index: 1000;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 600ms, visibility 600ms;
  transition: opacity 600ms, visibility 600ms;

  ${({ transition }) =>
    transition &&
    `
 visibility: visible;
  opacity: 1;
      `};

  @media (min-width: 768px) {
    padding: 32px;
    margin-bottom: 32px;
  }

  @media (min-width: 1920px) {
    padding: 32px 124px;
    margin-bottom: 32px;
  }
  &:hover ${HidenHeaderMenu} {
    height: 432px;
    background-color: white;
    box-shadow: 0px 0px 25px rgba(123, 148, 157, 0.5);
    border-radius: 0px 0px 15px 15px;
  }
`;

export const HeadTitle = styled.h1`
  font-size: ${(props) => (props.fs ? props.fs : 38)}px;
  font-family: var(--boldFont);
  color: var(--mainBlack);
  line-height: ${(props) => (props.lh ? props.lh : 46)}px;
  margin-bottom: ${(props) => (props.mb ? props.mb : 0)}px;
  margin-top: ${(props) => (props.mt ? props.mt : 0)}px;
  margin-right: ${(props) => (props.mr ? props.mr : 0)}px;

  @media (min-width: 768px) {
    line-height: ${(props) => (props.lh ? props.lh : 74)}px;
    margin-bottom: ${(props) => (props.mb ? props.mb : 0)}px;
    font-size: ${(props) => (props.fs ? props.fs : 74)}px;
  }
  @media (min-width: 1366px) {
    line-height: ${(props) => (props.lh ? props.lh : 74)}px;
    margin-bottom: ${(props) => (props.mb ? props.mb : 0)}px;
    font-size: ${(props) => (props.fs ? props.fs : 74)}px;
  }
  @media (min-width: 1920px) {
    line-height: ${(props) => (props.lh ? props.lh : 110)}px;
    font-size: ${(props) => (props.fs ? props.fs : 74)}px;
  }
`;

export const HeadSubTitle = styled.p`
  font-family: var(--mainFont);
  font-size: 41px;
  line-height: 48px;

  @media (min-width: 768px) {
    font-size: 48px;
    line-height: 56px;
  }

  @media (min-width: 1920px) {
    font-size: 64px;
  }
`;

export const IntroTitle = styled.p`
  margin-top: 24px;
  font-size: 24px;
  line-height: 26px;
  font-weight: 500;
  font-family: var(--mediumFont);

  color: var(--grayDark);

  @media (min-width: 768px) {
    font-size: 48px;
    line-height: 54px;
    margin-top: 32px;
  }
  @media (min-width: 1920px) {
    margin-top: -15px;
    line-height: 56px;
  }
`;

export const HeroDemoBtn = styled.div`
  position: absolute;
  cursor: pointer;
  background: var(--mainBlack);
  border: 1px solid #101820;
  padding: 8px 66px;
  color: #fff;
  border-radius: 30px;
  font-size: 18px;
  top: 80%;
  left: 50%;
  transition: ease 0.3s;

  transform: translate(-50%, -50%);
  @media (min-width: 768px) {
    font-size: 24px;
    padding: 16px 161px;
  }
  @media (min-width: 1366px) {
    top: 26%;
    background: none;
    color: var(--mainBlack);
  }

  @media (min-width: 1420px) {
    font-size: 24px;
    padding: 16px 161px;

    &:hover {
      background: var(--mainBlack);
      color: #fff;
      font-family: var(--rosaFont);
    }
  }
`;

export const SecondMainTitle = styled.h2`
  font-size: ${(props) => (props.fs ? props.fs : 28)}px;
  font-family: var(--boldFont);
  color: var(--mainBlack);
  text-align: center;
  line-height: ${(props) => (props.lh ? props.lh : 32)}px;
  margin-bottom: ${(props) => (props.mb ? props.mb : 24)}px;
  @media (min-width: 768px) {
    line-height: ${(props) => (props.lh ? props.lh : 56)}px;
    font-size: ${(props) => (props.fs ? props.fs : 49)}px;
  }
  @media (min-width: 1366px) {
    line-height: ${(props) => (props.lh ? props.lh : 56)}px;
    font-size: ${(props) => (props.fs ? props.fs : 48)}px;
  }
  @media (min-width: 1920px) {
    line-height: ${(props) => (props.lh ? props.lh : "74")}px;
    font-size: ${(props) => (props.fs ? props.fs : 64)}px;
  }
`;

export const IntegrationsTitle = styled(SecondMainTitle)`
  margin-right: 6px;
  margin-bottom: 0;
  @media (min-width: 1366px) {
    margin-right: 10px;
  }
`;

export const IntegrationsWrapper = styled.div`
  width: 250px;
  margin: 32px auto 0;
  text-align: center;

  @media (min-width: 768px) {
    width: 704px;
  }
  @media (min-width: 1920px) {
    width: 861px;
  }
`;

export const TabletWrapper = styled.div`
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-around;
  }
`;

export const SubTitleGray = styled.p`
  font-family: var(--mainFont);
  color: var(--grayDark);
  line-height: 20px;
  font-size: ${(props) => props.size}px;
  margin-bottom: 16px;
  font-weight: ${(props) => (props.weight ? props.weight : 400)};
  @media (min-width: 768px) {
    line-height: 36px;
  }
  @media (min-width: 1366px) {
    font-size: ${(props) => (props.size ? props.size : "32")}px;
  }
  @media (min-width: 1920px) {
    line-height: ${(props) => (props.lh ? props.lh : 42)}px;
    font-size: ${(props) => (props.size ? props.size : "36")}px;
  }
`;

export const SubTitleGraySpan = styled.span`
  color: var(--grayDark);
  font-size: ${(props) => props.size}px;
  margin-bottom: 16px;
  font-weight: ${(props) => (props.weight ? props.weight : 400)};
  @media (min-width: 768px) {
    margin-right: 10px;
  }
  @media (min-width: 1366px) {
    margin-right: ${(props) => (props.mr ? props.mr : 0)};
  }
`;

export const Glitch = keyframes`

    0% {
    transform: none;
    opacity: 0.25;
  }
  7% {
    transform: translate(-2px, -3px);
    opacity: 0.5;
  }
  10% {
    transform: none;
    opacity: 0.25;
  }
  27% {
    transform: none;
    opacity: 0.25;
  }
  30% {
    transform: translate(-5px, -2px);
    opacity: 0.4;
  }
  35% {
    transform: none;
    opacity: 0.25;
  }
  52% {
    transform: none;
    opacity: 0.25;
  }
  55% {
    transform: translate(-5px, -1px);
    opacity: 0.3;
  }
  50% {
    transform: none;
    opacity: 0.1;
  }
  72% {
    transform: none;
    opacity: 0.2;
  }
  75% {
    transform: translate(-2px, -6px);
    opacity: 0.4;
  }
  80% {
    transform: none;
    opacity: 0.2;
  }
  100% {
    transform: none;
    opacity: 0.1;
  }
  
`;

export const ItalicTitle = styled.span`
  color: ${(props) => (props.color ? props.color : `var(--grayDark)`)};
  font-size: 20px;
  font-family: var(--italicFont);
  animation: infinite 2.8s ${Glitch};
  @media (min-width: 768px) {
    font-size: 32px;
    line-height: 36px;
  }
  @media (min-width: 1920px) {
    font-size: 36px;
    line-height: 41px;
  }
`;
export const QuotesMainWrapper = styled.div`
  position: relative;
  @media (min-width: 1920px) {
    padding: 0 260px;
  }
`;

export const QuotesWrapper = styled.div`
  width: 234px;
  background-color: #fff;
  font-size: 16px;
  line-height: 18px;
  padding: 16px;
  margin-bottom: ${(props) => (props.bottom ? props.bottom : "")}px;
  margin-left: ${(props) => (props.float ? "auto" : "")};
  border-radius: ${(props) => props.border};
  @media (min-width: 768px) {
    width: 414px;
    padding: 32px;
    font-size: 24px;
    line-height: 26px;
  }
  @media (min-width: 1366px) {
    width: 673px;
    padding: 32px;
    font-size: 24px;
    line-height: 26px;
  }
  @media (min-width: 1920px) {
    width: 733px;
    padding: 62px;
    font-size: 24px;
    line-height: 26px;
  }

  & span {
    font-family: var(--mediumFont);
  }
`;

export const QuotesAuthorTitle = styled.p`
  text-align: end;
  font-size: 16px;
  margin-top: 24px;
  font-weight: 500;
  font-family: var(--mediumFont);
  @media (min-width: 768px) {
    margin-top: 48px;
    font-size: 24px;
  }
`;

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: baseline;

  margin-bottom: 64px;
  @media (min-width: 768px) {
    padding: 0 32px;
    align-items: center;
  }
  @media (min-width: 1366px) {
    padding: 0;
    padding-top: 64px;
    margin-bottom: 70px;
    align-items: center;
  }
  @media (min-width: 1920px) {
    padding: 122px;
    align-items: center;
  }
`;

export const CheckBoxWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 45px;
  height: 16px;
  border-radius: 15px;
  background: #bcccd1;
  cursor: pointer;
  @media (min-width: 768px) {
    width: 84px;
    height: 30px;
  }
  &::after {
    content: "";
    display: block;
    border-radius: 10px;
    width: 20px;
    height: 12px;
    margin: 2px;
    background: #f0f4f5;
    transition: 0.2s;
    @media (min-width: 768px) {
      width: 44px;
      height: 22px;
      margin: 4px;
    }
  }
`;
export const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  &:checked + ${CheckBoxLabel} {
    background: #bcccd1;
    &::after {
      content: "";
      display: block;
      border-radius: 10px;
      width: 20px;
      height: 12px;
      margin-left: 21px;
      transition: 0.2s;
      @media (min-width: 768px) {
        width: 44px;
        height: 22px;
        margin-left: 35px;
      }
    }
  }
`;

export const AdvantageContentWrapper = styled.div`
  @media (min-width: 768px) {
    position: relative;
    bottom: 400px;
  }
  /* @media (min-width: 1366px) {
    position: relative;
    bottom: 400px;
    inset: 0 0 0 300px;
  } */
`;

export const AdvantageTitleWrapper = styled.div`
  margin-left: 10px;
  margin-top: 24px;
  display: flex;

  @media (min-width: 768px) {
    margin-left: 116px;
  }

  @media (min-width: 1366px) {
    width: ${(props) => props.wdth}px;
    display: flex;
    margin-top: 0px;
    justify-content: space-between;
    align-items: center;
  }
`;

export const AdvantageTitle = styled.span`
  color: var(--mainBlack);
  font-family: var(--boldFont);
  line-height: 24px;
  font-size: 20px;
  @media (min-width: 768px) {
    font-size: 42px;
    line-height: 48px;
  }
  @media (min-width: 1920px) {
    font-size: 48px;
    line-height: 54px;
  }
`;

export const StyledAdvantageImgBlock = styled.div`
  background-repeat: no-repeat;
  background-image: url(${(props) => props.bg});
  height: ${(props) => props.height}px;
  width: ${(props) => (props.width ? props.width : "100%")}px;
  margin: ${(props) => (props.center ? "0 auto" : "")};
  background-size: 100% 100%;
  @media (min-width: 768px) {
    position: absolute;
    inset: 0;
    top: ${(props) => (props.bottom ? props.bottom : "")}px;
    z-index: -1;
  }
  @media (min-width: 1366px) {
    position: absolute;
    inset: ${(props) => (props.inset ? props.inset : "")};
    top: ${(props) => (props.bottom ? props.bottom : "")}px;
    z-index: -1;
    background-size: ${(props) => (props.bgSize ? props.bgSize : "100% 100%")};
  }
`;

export const SlideWrapper = styled.div`
  @media (min-width: 768px) {
    margin-top: 450px;
  }
  @media (min-width: 1366px) {
    &.carousel__inner-slide {
      display: flex;
    }
  }
`;

export const StyledSlide = styled(Slide)`
  @media (min-width: 1366px) {
    &.carousel__inner-slide {
      display: flex;
    }
  }
`;

export const AdvantageColorTitle = styled(AdvantageTitle)`
  color: ${(props) => props.color};
  margin: ${(props) => (props.margin ? props.margin : "")};
  font-size: 16px;
  @media (min-width: 768px) {
    font-size: 42px;
    margin-right: 10px;
  }
  @media (min-width: 1366px) {
    font-size: 48px;
    margin-right: 10px;
  }
  @media (min-width: 1920px) {
    font-size: 64px;
    line-height: 54px;
    margin-right: 0;
  }
`;

export const AdvantageInfoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    flex-direction: column;
    padding: 0 32px;
  }

  @media (min-width: 1366px) {
    padding: 0px;
    width: 858px;
  }

  @media (min-width: 1920px) {
    padding: 0 122px;
    width: 958px;
  }
`;

export const AdvantageInfoBlock = styled.div`
  font-family: var(--mediumFont);
  font-size: 20px;
  color: #fff;
  display: flex;
  flex-wrap: nowrap;

  @media (min-width: 768px) {
    font-size: 40px;
  }

  @media (min-width: 1366px) {
    font-size: 48px;
  }

  @media (min-width: 1920px) {
    font-size: 64px;
  }
`;

export const StyledImgBlock = styled.div`
  background-image: url(${(props) => props.bg});
  height: ${(props) => props.height}px;
  width: ${(props) => (props.width ? props.width : "100%")}px;
  margin: ${(props) => (props.center ? "0 auto" : "")};
  background-size: 100% 100%;
  @media (min-width: 768px) {
    /* position: relative; */
    bottom: ${(props) => (props.bottom ? props.bottom : "")}px;
    z-index: -1;
  }
  @media (min-width: 1366px) {
    bottom: ${(props) => (props.bottom ? props.bottom : "")}px;
    z-index: -1;
  }
`;
export const QuestionTitle = styled.span`
  color: var(--blueMain);
  font-size: 21px;
  margin-left: 12px;
  @media (min-width: 768px) {
    font-size: 42px;
    margin-left: 18px;
  }
  @media (min-width: 1366px) {
    font-size: 24px;
    margin-left: 46px;
    line-height: 26px;
    font-family: var(--mediumFont);
  }
`;

export const InternalsWrapper = styled.div`
  text-align: center;
  margin-left: 45%;
  margin-top: 300px;
  @media (min-width: 1366px) {
    width: 655px;
    text-align: start;
  }
`;

export const InternalsContentWrapper = styled.div`
  @media (min-width: 1366px) {
    display: flex;
    align-items: center;
  }
  @media (min-width: 1920px) {
    margin-left: 91px;
  }
`;

export const InternalsMainWrapper = styled.div`
  @media (min-width: 768px) {
    margin-top: 330px;
  }
`;

export const InternalsTextWrapper = styled.div`
  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
  }
  @media (min-width: 1366px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* justify-content: unset; */
  }
  @media (min-width: 1920px) {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-bottom: 32px;
  }
`;

export const SliderDotWrapper = styled.div`
  margin: 0 auto;
  text-align: center;

  & .dot___3c3SI {
    width: 100px;
    border: none;
    background-color: ${(props) => (props.bg ? props.bg : "#bcccd1")};
    border-radius: 10px;
    margin-bottom: 20px;
  }

  & .carousel__dot--selected {
    background-color: ${(props) =>
      props.bgSelected ? props.bgSelected : "#101820"};
    border: none;
    border-radius: 10px;
  }
`;

export const AsquadFontTitle = styled.h2`
  font-family: var(--rosaFont);
  font-size: 40px;
  text-align: center;
  @media (min-width: 768px) {
    font-size: 74px;
  }
`;

export const AwesomeWrapper = styled.div`
  @media (min-width: 768px) {
    margin-top: 122px;
    margin-bottom: 300px;
  }
  @media (min-width: 1366px) {
    margin: 0 auto;
    text-align: center;
  }
`;

export const HoldOnWrapper = styled.div`
  margin: 160px 0;
  @media (min-width: 768px) {
    margin-top: 122px;
    margin-bottom: 300px;
  }
  @media (min-width: 1366px) {
    width: 900px;
    margin: 0 auto;
    margin-top: 122px;
    margin-bottom: 282px;
    text-align: center;
  }
`;

export const SubTitleTextWrapper = styled.div`
  line-height: ${(props) => (props.lh ? props.lh : 20)}px;
  font-size: ${(props) => (props.fs ? props.fs : 18)}px;
  color: ${(props) => (props.color ? props.color : `var(--grayDark)`)};
  margin-bottom: ${(props) => (props.mb ? props.mb : 16)}px;
  & span {
    font-family: var(--mediumFont);
  }
  @media (min-width: 768px) {
    font-size: ${(props) => (props.fs ? props.fs : 32)}px;
    margin-bottom: ${(props) => (props.mb ? props.mb : 24)}px;
    line-height: ${(props) => (props.lh ? props.lh : 32)}px;
  }
  @media (min-width: 1366px) {
    line-height: ${(props) => (props.lh ? props.lh : 32)}px;
  }
  @media (min-width: 1920px) {
    font-size: ${(props) => (props.fs ? props.fs : 32)}px;
    line-height: ${(props) => (props.lh ? props.lh : 42)}px;
  }
`;

export const BlackBoxWrapper = styled.div`
  background-color: var(--mainBlack);
  border-radius: 10px;
  padding: 16px;
  text-align: center;
  @media (min-width: 768px) {
    height: 824px;
    border-radius: 15px;
  }
  @media (min-width: 1366px) {
    height: 547px;
    border-radius: 15px;
  }
  @media (min-width: 1920px) {
    height: 665px;
    border-radius: 15px;
  }
`;

export const PeculiaritiesBlackBox = styled(BlackBoxWrapper)`
  padding: 64px 16px;

  @media (min-width: 1366px) {
    display: flex;
    height: 180px;
    font-size: 42px;
    padding: 61px 44px;
    align-items: center;
    justify-content: space-between;
  }
`;

export const AdminPanelBlackBox = styled(BlackBoxWrapper)`
  padding: 64px 16px;

  @media (min-width: 1366px) {
    display: flex;
    font-size: 42px;
    padding: 61px 44px;
    align-items: center;
    justify-content: space-between;
  }
`;

export const NumberChapterColorTitle = styled.p`
  margin-top: 40px;
  margin-bottom: 16px;
  font-size: 20px;
  line-height: 24px;
  font-family: var(--boldFont);
  color: ${(props) => (props.color ? props.color : `var(--grayDark)`)};

  @media (min-width: 768px) {
    font-size: 42px;
    margin-bottom: 32px;
  }
  @media (min-width: 1366px) {
    font-size: 42px;
    line-height: 48px;
    margin-bottom: 32px;
  }
  @media (min-width: 1920px) {
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 32px;
  }
`;

export const NumberChapterDeskWrapper = styled.div`
  @media (min-width: 1366px) {
    display: flex;
    align-items: center;
    height: 485px;
  }
  @media (min-width: 1920px) {
    display: flex;
    align-items: center;
    height: 580px;
    margin-top: 20px;
    margin-left: 90px;
  }
`;

export const NumberChapterDeskTextWrapper = styled.div`
  @media (min-width: 1366px) {
    width: 620px;
    text-align: left;
    margin-top: 122px;
    margin-left: 130px;
  }
  @media (min-width: 1920px) {
    width: 620px;
    text-align: left;
    margin-left: 90px;
  }
`;

export const TechnologiesTitleWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-evenly;
  @media (min-width: 768px) {
    margin-bottom: 32px;
  }
  @media (min-width: 1366px) {
    margin: 0 auto;
    margin-bottom: 32px;
    width: 641px;
  }
  @media (min-width: 1290px) {
    margin: 0 auto;
    margin-bottom: 16px;
    margin-top: 126px;
    line-height: 73px;
    width: 700px;
  }
`;

export const TechnologiesDeskWrapper = styled.div`
  margin-top: 96pxr;
  @media (min-width: 1366px) {
    display: flex;
  }
`;

export const TechnologiesSubTitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 90px;
  @media (min-width: 768px) {
    width: 450px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
`;

const FirstElementTechStyle = css`
  color: #0062eb;
`;

const IconElementStyle = css`
  img {
    -webkit-filter: brightness(0) saturate(100%) invert(95%) sepia(36%)
      saturate(462%) hue-rotate(167deg) brightness(83%) contrast(96%);
    filter: brightness(0) saturate(100%) invert(95%) sepia(36%) saturate(462%)
      hue-rotate(167deg) brightness(83%) contrast(96%);
  }
`;
export const TechnologiesSubTitle = styled.span`
  font-size: 16px;
  margin-right: 16px;
  color: ${(props) => (props.color ? props.color : `var(--grayDark)`)};

  @media (min-width: 768px) {
    font-size: 32px;
    margin-right: 0;
    margin-bottom: 92px;
  }
  ${({ changeStyle }) => {
    if (changeStyle) {
      return FirstElementTechStyle;
    }
  }}
`;

export const TechnologiesIconWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  width: 240px;
  justify-content: space-between;
  margin-bottom: 50px;
  @media (min-width: 768px) {
    width: 649px;
    margin-bottom: 100px;
    display: flex;
    align-items: center;
  }
  @media (min-width: 1366px) {
    width: 600px;
  }

  ${({ changeStyle }) => {
    if (changeStyle) {
      return IconElementStyle;
    }
  }}
`;

export const BusinessSwitcherWrapper = styled.div`
  position: relative;
  top: 500px;
  left: 160px;
  @media (min-width: 768px) {
    top: 800px;
    left: 45%;
  }
  @media (min-width: 1366px) {
    top: 630px;
    left: 89%;
  }
`;

export const BusinessWrapper = styled.div`
  padding: 0 16px;
  @media (min-width: 768px) {
    padding: 0 32px;
  }
  @media (min-width: 1366px) {
    padding: 0 64px;
  }
`;

export const BusinessDeskWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  height: 659px;
  margin-bottom: 34px;
`;
export const DemoBtn = styled.div`
  justify-content: center;
  font-size: 18px;
  padding: 6px 0;
  cursor: pointer;
  width: 180px;
  background-color: ${(props) => (props.bg ? props.bg : `var(--mainBlack)`)};
  border-radius: 30px;
  color: ${(props) => (props.color ? props.color : `var(--mainBlack)`)};
  margin: ${(props) => (props.mr ? props.mr : "")};
  transition: ease 0.4s;
  text-align: center;
  border: 1px solid var(--mainBlack);
  @media (min-width: 768px) {
    width: 400px;
    font-size: 24px;
    padding: 16px 0;
    &:hover {
      background-color: ${(props) =>
        props.bg ? props.bg : `var(--mainBlack)`};
      border: 1px solid #fff;
      color: ${(props) => (props.color ? props.color : "white")};
      font-size: 23px;
      font-family: var(--rosaFont);
    }
  }
  @media (min-width: 1366px) {
    width: 257px;
    font-size: 24px;
    padding: 7px 0;
  }
`;

export const PeculiaritiesTitleTxt = styled.p`
  margin-top: 48px;
  margin-bottom: 48px;
  font-size: 24px;
  margin-left: ${(props) => (props.ml ? props.ml : "")}px;
  color: ${(props) => (props.color ? props.color : "black")};
`;

export const ReferenceLogoWrapper = styled.div`
  margin-top: 100px;
  text-align: center;
  margin-bottom: 68px;
  @media (min-width: 768px) {
    margin-top: 0;
  }
`;
export const ReferenceWrapper = styled.div`
  text-align: center;
  @media (min-width: 768px) {
    display: flex;
    padding: 32px;
    justify-content: space-between;
  }

  @media (min-width: 768px) {
    padding: 64px;
  }
`;

export const LinkMainWrapper = styled.div`
  @media (min-width: 768px) {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  @media (min-width: 1366px) {
    flex-direction: inherit;
    width: 380px;
  }
`;

export const ReferenceLinkTitle = styled.span`
  font-size: 18px;
  margin-bottom: 32px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  font-family: var(--mediumFont);
  color: var(--mainBlack);

  &:nth-child(2) {
    margin-bottom: 24px;
  }

  &:nth-child(3) {
    color: var(--grayDark);
    margin-bottom: 16px;
  }
  &:nth-child(4) {
    color: var(--grayDark);
    margin-bottom: 36px;
  }
  &:nth-last-child(1) {
    margin-bottom: 64px;
  }
  @media (min-width: 768px) {
    font-size: 20px;
    margin-bottom: 62px;
    line-height: 24px;

    &:nth-child(2) {
      margin-bottom: 24px;
    }

    &:nth-child(3) {
      color: var(--grayDark);
      margin-bottom: 24px;
    }
    &:nth-child(4) {
      color: var(--grayDark);
      margin-bottom: 62px;
    }
    &:nth-last-child(2) {
      margin-bottom: 64px;
    }
  }
  @media (min-width: 1366px) {
    &:nth-child(3n + 1) {
      order: 1;
    }
  }
`;

export const LinksWrapper = styled.div`
  display: flex;
  width: 280px;
  margin: 0 auto;
  justify-content: space-between;
  margin-bottom: 92px;

  & > img:hover {
    cursor: pointer;
  }
  & > a > svg {
    cursor: pointer;
    path {
      transition: fill 0.3s;
    }
    &:hover {
      path {
        fill: #101820;
      }
    }
  }

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    width: 160px;
    margin-bottom: 32px;
  }
`;

export const StyledBottomLine = styled.hr`
  border: 1px solid #bcccd1;
  color: #bcccd1;
  background-color: #bcccd1;
  height: 1px;
  margin-bottom: 64px;
  width: 320px;
  border-radius: 20px;
  @media (min-width: 768px) {
    margin-top: 150px;
    width: 701px;
  }
  @media (min-width: 1366px) {
    margin-top: 150px;
    width: 1300px;
  }
`;

export const FooterTitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 32px;
  @media (min-width: 768px) {
    text-align: end;
  }
`;

export const FooterTitle = styled.p`
  font-size: 14px;
  color: ${(props) => (props.color ? props.color : `var(--blackMain)`)};
`;

export const FooterIconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
  @media (min-width: 768px) {
    width: 386px;
  }
`;

export const SlideNavBarMenu = styled(SlidingPane)`
  &.slide-pane_from_right {
    height: 408px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 0px 0px 25px rgba(123, 148, 157, 0.5);
  }
`;

export const NavBarLinkWrapper = styled.div`
  margin-top: 46px;
`;

export const NavBarLink = styled.a`
  display: block;
  font-size: 18px;
  color: var(--mainBlack);
  margin-bottom: 36px;
  &:nth-child(2) {
    margin-bottom: 24px;
  }
  @media (min-width: 768px) {
    font-size: 20px;
  }
`;

export const NavBarLinkInnerWrapper = styled.div`
  border-left: 2px solid #0062eb;
  height: 56px;
  padding-left: 18px;
  margin-bottom: 34px;
`;

export const NavBarLinkInner = styled.a`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  font-size: 18px;
  color: var(--grayDark);
`;

export const DemoModalWrapperActive = styled.div`
  /* height: min-content;
  transition: all 1s;
  position: fixed;
  right: 0;
  top: 0;
  background-color: white;
  height: 700px;
  z-index: 999; */
  position: fixed;
  transition: all 1s;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 1000;
  background-color: white;
  overflow-x: auto;
  @media (min-width: 768px) {
    padding: 0px 172px 0px 88px;
    margin-left: 84px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  @media (min-width: 1366px) {
    padding: 0px 340px 32px 122px;
    margin-left: 218px;
  }
  @media (min-width: 1920px) {
    padding: 0px 835px 32px 117px;
    margin-left: 718px;
  }
`;

export const DemoModalContent = styled.div`
  padding: 16px;
  border-radius: 20px;
  /* width: 375px; */
  text-align: start;
  @media (min-width: 768px) {
    padding: 0;
  }
`;

export const DemoModalContentWrapper = styled.div`
  scroll-behavior: auto;
  @media (min-width: 768px) {
    /* width: 484px;
    margin: 0 auto; */
  }
`;

export const ActionRadioGroupWrapper = styled.div`
  width: 160px;
  @media (min-width: 768px) {
    width: 224px;
  }
  @media (min-width: 1366px) {
    width: 97%;
    margin-bottom: 24px;
    /* margin-left: ${(props) => (props.ml ? "auto" : "0px")};
    margin-right: ${(props) => (props.mr ? "auto" : "0px")}; */
  }
`;

export const DemoButtonWrapper = styled.div`
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    margin-top: 67px;
  }
  @media (min-width: 1366px) {
    margin-left: auto;
    margin-top: 79px;
    width: 480px;
  }
  @media (min-width: 1920px) {
    margin-left: auto;
    margin-top: 99px;
    width: 480px;
  }
`;

export const DemoModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  @media (min-width: 768px) {
    justify-content: end;
  }
`;

export const DemoForm = styled.form``;

export const DemoFormActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 24px; */
  @media (min-width: 1366px) {
    display: block;
    margin-bottom: 44px;
  }
  @media (min-width: 1920px) {
    display: block;
    margin-bottom: 64px;
  }
`;

export const DemoFormInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DemoFormInputLabel = styled.label`
  color: var(--grayDark);
  margin-left: 16px;
  font-size: 14px;
  margin-top: 16px;
  margin-bottom: 2px;
  line-height: 16px;
  @media (min-width: 768px) {
    font-size: 18px;
    margin-top: 24px;
    line-height: 20px;
  }
  @media (min-width: 1366px) {
    display: block;
    margin-top: 0;
  }
`;

export const DemoFormInput = styled.input`
  background-color: var(--greyLight);
  border-radius: 15px;
  outline: none;
  color: var(--mainBlack);
  border: ${(props) =>
    props.error ? "1px solid #EB0075" : "1px solid transparent"};
  box-shadow: ${(props) =>
    props.error ? "0px 0px 5px rgba(235, 0, 117, 0.5)" : "none"};
  height: 26px;
  padding-left: 16px;
  caret-color: #0062eb;
  transition: ease 0.3s;
  /* margin-bottom: 16px; */
  &:focus {
    border: ${(props) =>
      props.error ? "1px solid #EB0075" : "1px solid #0062eb"};
    border-color: ${(props) =>
      props.error ? "1px solid #EB0075" : "1px solid #0062eb"};
    outline: none;
    box-shadow: ${(props) =>
      props.error
        ? "0px 0px 5px rgba(235, 0, 117, 0.5)"
        : " 0px 0px 5px rgba(0, 98, 235, 0.5)"};
  }
  &:hover {
    border: ${(props) =>
      props.error ? "1px solid #EB0075" : "1px solid #0062eb"};
    border-color: ${(props) => (props.error ? "#EB0075" : "#0062eb")};
    outline: none;
    box-shadow: ${(props) =>
      props.error
        ? "0px 0px 5px rgba(235, 0, 117, 0.5)"
        : " 0px 0px 5px rgba(0, 98, 235, 0.5)"};
  }
  @media (min-width: 768px) {
    font-size: 20px;
    height: 36px;
    border-radius: 20px;
    /* margin-bottom: 24px; */
  }
  @media (min-width: 1366px) {
    padding: 0 0 0 16px;
    height: 38px;
    width: 93%;
  }
`;

export const DemoFormErrorTitle = styled.p`
  font-size: 12px;
  color: #eb0075;
  margin-left: 16px;
  @media (min-width: 768px) {
    font-size: 16px;
  }
  @media (min-width: 1366px) {
    font-size: 16px;
    position: absolute;
  }
`;

export const DemoFormTextArea = styled(TextareaAutosize)`
  resize: none;
  padding: 8px 16px;
  background-color: var(--greyLight);
  font-size: 16px;
  line-height: 18px;
  border-radius: 20px;
  font-family: var(--mainFont);
  transition: ease 0.3s;
  border: 1px solid transparent;
  caret-color: #0062eb;
  height: 88px;
  &:hover {
    border: 1px solid #0062eb;
    border-color: #0062eb;
    outline: none;
    box-shadow: 0px 0px 5px rgba(0, 98, 235, 0.5);
  }
  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 22px;
  }
  @media (min-width: 1366px) {
    padding: 8px 16px;
    width: 95%;
  }
`;

export const DemoFormColumnWrapper = styled.div`
  display: ${(props) => (props.change ? "flex" : "")};
  flex-flow: ${(props) => (props.change ? "column" : "")};
  @media (min-width: 1366px) {
    display: block;
    column-count: 2;
  }
`;

export const DemoInputPositionWrapper = styled.div``;

export const DemoTitle = styled.p`
  font-weight: 700;
  font-size: 28px;
  margin-top: 48px;
  margin-bottom: 24px;
  @media (min-width: 768px) {
    font-size: 48px;
    margin-top: 32px;
    margin-bottom: 64px;
    line-height: 55px;
  }
  @media (min-width: 1920px) {
    margin-top: 136px;
    margin-bottom: 96px;
    font-family: var(--boldFont);
  }
`;

export const DemoFormTittle = styled.p`
  color: var(--grayDark);
  margin: 0 0 16px 16px;
  line-height: 24px;
  @media (min-width: 768px) {
    font-size: 20px;
    margin: 0 0 16px 24px;
  }
  @media (min-width: 1366px) {
    font-size: 20px;
    margin: 0 0 16px 24px;
    line-height: 20px;
  }
  @media (min-width: 1920px) {
    font-size: 18px;
    margin: 0 0 16px 16px;
  }
`;

export const DemoFormInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    margin-bottom: 20px;
  }
  @media (min-width: 1366px) {
    margin-bottom: 44px;
    display: block;
  }
  @media (min-width: 1920px) {
    margin-bottom: 84px;
  }
`;

export const SubmitFormBtn = styled.button`
  align-items: center;
  padding: 6px 16px;
  background-color: ${(props) => (props.bg ? props.bg : "#101820")};
  border-radius: 15px;
  border: none;
  cursor: pointer;
  color: ${(props) => (props.color ? props.color : "#fff")};
  font-size: 16px;
  width: 100%;
  margin-top: ${(props) => (props.mt ? props.mt : "40")}px;
  font-family: var(--mainFont);
  transition: ease 0.3s;

  &:hover {
    background-color: ${(props) => (props.hover ? props.hover : "red")};
    color: ${(props) => (props.hoverTxt ? props.hoverTxt : "red")};
    font-family: var(--rosaFont);
  }

  @media (min-width: 768px) {
    width: 208px;
    font-size: 24px;
    border-radius: 20px;
  }

  &:disabled {
    background: #f0f4f5;
    color: #bcccd1;
  }
`;

export const ReferenceLinksWrapper = styled.div`
  @media (min-width: 768px) {
    flex-direction: column;
    text-align: start;
  }
`;

export const FooterWrapper = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
`;

export const AdvantageColorWrapper = styled.div`
  height: 100%;
  background-color: ${(props) => (props.bg ? props.bg : "#101820")};
  opacity: ${({ isHovering }) => (isHovering ? 1 : 0)};
  transition: all 0.4s ease-in-out;
  text-align: initial;

  @media (min-width: 1366px) {
    border-radius: 15px;
    padding: 32px;
  }
`;

export const AdvantageListItem = styled.li`
  margin-bottom: 14px;
  color: #fff;

  @media (min-width: 1366px) {
    font-size: 24px;
  }
  & > span {
    font-family: var(--rosaFont);
  }
`;

export const AdvantageListTitle = styled.p`
  font-size: 18px;
  color: #fff;

  @media (min-width: 1366px) {
    font-size: 24px;
    font-family: var(--boldFont);
  }
`;

export const AdvantageListBetweenTitle = styled.p`
  margin: 20px 0;
  color: #fff;
  opacity: 0.6;
  font-size: 22px;
`;

export const AdvantageModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledFormTelDropdown = styled(PhoneInput)`
  font-family: var(--mainFont);

  caret-color: #0062eb;
  &.react-tel-input input {
    font-size: 16px;
    width: 100%;
    background-color: var(--greyLight);
    border-radius: 15px;
    outline: none;
    color: var(--mainBlack);
    transition: ease 0.3s;
    border: ${(props) =>
      props.error ? "1px solid #EB0075" : "1px solid transparent"};
    box-shadow: ${(props) =>
      props.error ? "0px 0px 5px rgba(235, 0, 117, 0.5)" : "none"};
    height: 30px;
    caret-color: #0062eb;
    &:hover {
      border: 1px solid #0062eb;
      border-color: #0062eb;
      outline: none;
      box-shadow: 0px 0px 5px rgba(0, 98, 235, 0.5);
    }
    &:focus {
      border: 1px solid #0062eb;
      border-color: #0062eb;
      outline: none;
      box-shadow: 0px 0px 5px rgba(0, 98, 235, 0.5);
    }
    @media (min-width: 768px) {
      font-size: 20px;
      height: 40px;
      border-radius: 20px;
    }
    @media (min-width: 1366px) {
      width: 97%;
    }
  }
  &.react-tel-input {
    .flag-dropdown {
      background-color: inherit;
      border: none;
    }
    &.react-tel-input {
      .selected-flag {
        padding: 0 0 0 16px;
        background-color: inherit;
        &:hover {
          background-color: inherit;
        }
      }
    }
    &.react-tel-input {
      .selected-flag {
        .flag {
          .arrow {
            display: none;
          }
        }
      }
    }
    &.react-tel-input {
      .country-list {
        border-radius: 5px;
        .search {
          padding: 0;
          .search-emoji {
            display: none;
          }
        }
      }
    }
    &.react-tel-input {
      .country-list {
        @media (min-width: 1366px) {
          position: fixed;
        }
        .search {
          .search-box {
            border: none;
            background-color: white;
            padding: 24px 26px;
            border-radius: 0px;
            border-bottom: 1px solid #7b949d;
            margin-bottom: 24px;
            &::placeholder {
              color: #7b949d;
            }
          }
        }
      }
    }
    &.react-tel-input .country-list .search-box {
      margin-left: 0px;
      width: 100%;
    }
    &.react-tel-input .country-list .country {
      display: flex;
    }
    &.react-tel-input .country-list .country .dial-code {
      color: #7b949d;
      margin-left: auto;
    }
  }
`;
