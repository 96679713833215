/* eslint-disable no-unused-vars */
import {
  SubTitleTextWrapper,
  NumberChapterColorTitle,
  NumberChapterDeskTextWrapper,
} from "../../../style";

import { useState, useEffect, useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { Context } from "../../Container";

import threeImg from "../../../img/numbersThreeImg.svg";

import { Global2 } from "../../Globals/GlobalNumbers/style";

const Numbers3 = () => {
  const isMobileScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const isTableScreen = useMediaQuery({ query: "(min-width: 768px)" });
  const isDescScreen = useMediaQuery({ query: "(min-width: 768px)" });
  const isBigDesktopScreen = useMediaQuery({ query: "(min-width: 1920px)" });

  let fs = "";
  let lh = "";
  let mb = "";
  let lhTxt = "";
  let fsSubTtl = "";
  if (isMobileScreen) {
    fs = 42;
  }
  if (isTableScreen) {
    fs = 48;
    lh = 56;
  }

  if (isDescScreen) {
    fs = 48;
    mb = 32;
    lh = 0;
    lhTxt = 36;
  }
  if (isBigDesktopScreen) {
    fs = 64;
    lhTxt = 42;
    fsSubTtl = 36;
  }

  const context = useContext(Context);
  const { isLeaving, scrollPointer, direction, setIsLoadedImages } = context;

  const [isReadyAnimationStart, setIsReadyAnimationStart] = useState(false);
  const [animationNumber, setAnimationNumber] = useState(null);

  useEffect(() => {
    if (scrollPointer !== null && scrollPointer === 15) {
      setTimeout(() => {
        setIsReadyAnimationStart(true);
      }, 0);
    }
  }, [scrollPointer]);

  useEffect(() => {
    if (scrollPointer === 15) {
      setAnimationNumber("Section-2");
    }
    if (
      (scrollPointer === 15 && isLeaving === "finish" && direction !== 0) ||
      scrollPointer !== 15
    ) {
      setAnimationNumber("leaveSection-2");
    }
  }, [scrollPointer, isLeaving, direction]);

  return (
    <Global2 isShow={isReadyAnimationStart} isAnimated={animationNumber}>
      <div style={{ display: "flex" }}>
        <img
          src={threeImg}
          zIndex="100"
          width="650"
          height="650"
          onLoad={() => setIsLoadedImages((prev) => prev + 1)}
          alt="img"
        />
        <NumberChapterDeskTextWrapper>
          <NumberChapterColorTitle color="#0FCA87">
            Fast Load Resistance Service
          </NumberChapterColorTitle>
          <SubTitleTextWrapper fs={fsSubTtl} lh={lhTxt} color="#F0F4F5">
            The client's <span>«buy now»</span> path to the{" "}
            <span>«payment successful»</span> status seems invisible.
          </SubTitleTextWrapper>
          <SubTitleTextWrapper lh={lhTxt} color="#F0F4F5">
            And so smooth that Stripe would be jealous.
          </SubTitleTextWrapper>
        </NumberChapterDeskTextWrapper>
      </div>
    </Global2>
  );
};

export default Numbers3;
