/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext, createContext, useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import { Context } from "../../Container";
import { CONFIG } from "../../../services/constants";
import {
  AdvantageModalWrapper,
  AdvantageModalList,
  AdvantageModalListSecond,
} from "./style";
import {
  SectionContainer,
  Container,
  SwitchWrapper,
  AdvantageTitleWrapper,
  AdvantageTitle,
  AdvantageColorTitle,
  QuestionTitle,
  AdvantageInfoWrapper,
  AdvantageColorWrapper,
  AdvantageListItem,
  AdvantageListTitle,
  AdvantageListBetweenTitle,
  AdvantageModalHeader,
} from "../../../style";
import {
  ShowStyle,
  TransformText,
  AnimationWrapperForHide,
} from "../../../styleAnimation";
import {
  AdvantageTag,
  AdvantageTempTag,
  AdvantageTransformTitle,
} from "./style";

import closeBtn from "../../../img/closeBtn.svg";
import Advantage from "./Advantage";
import Switcher from "./Switcher";
import Header from "../../../components/Header";

export const AdvantageContext = createContext({
  isReadyAnimationStart: false,
  isBetween: false,
  scrollPointer: null,
});

const SectionAdvantages = ({ index, value }) => {
  const context = useContext(Context);
  const {
    direction,
    isLeaving,
    scrollPointer,
    isPossibleScrolling,
    setIsLeaving,
    setIsPossibleScrolling,
  } = context;

  const [isReadyAnimationStart, setIsReadyAnimationStart] = useState(false);
  const [isLeaveReady, setIsLeaveReady] = useState(false);
  const [activeFirst, setActiveFirst] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const isDesktopScreen = useMediaQuery({ query: "(min-width: 1366px)" });

  const start = CONFIG[index].first;
  const end = CONFIG[index].last || start;
  const isBetween = scrollPointer >= start && scrollPointer <= end;

  const isReady = isReadyAnimationStart && isBetween;

  const isLeaveScreen =
    isLeaving === "ready" &&
    isBetween &&
    ((scrollPointer === start && direction === -1) ||
      (scrollPointer === end && direction === 1));

  const valueMemo = useMemo(
    () => ({
      isReadyAnimationStart,
      isBetween,
      scrollPointer,
    }),
    [isReadyAnimationStart, isBetween, scrollPointer]
  );

  useEffect(() => {
    if (isLeaving === "ready" && isBetween) {
      setIsLeaveReady(true);
    } else {
      setTimeout(() => {
        setIsLeaveReady(false);
      }, 600);
    }
  }, [isLeaving, isBetween]);

  useEffect(() => {
    if (isBetween) {
      setTimeout(() => {
        setIsReadyAnimationStart(true);
      }, 1000);
    } else {
      setIsReadyAnimationStart(false);
    }
  }, [isBetween]);

  useEffect(() => {
    if (isBetween && isLeaveReady) {
      setTimeout(() => {
        setIsLeaving("finish");
      }, 1200);
    }

    if (isBetween && !isPossibleScrolling) {
      setTimeout(() => {
        setIsPossibleScrolling(true);
      }, 1600);
    }
  }, [isLeaveReady]);

  return (
    <Container>
      <SectionContainer>
        {scrollPointer >= 1 && <Header value={value} />}
        <AnimationWrapperForHide isAnimated={true} isHide={isLeaveScreen}>
          <SwitchWrapper isHide={isLeaveReady}>
            <ShowStyle isAnimated={isReady} delay={1}>
              <Switcher
                name={"adv"}
                isToggle={scrollPointer === end}
                onToggle={() => {}}
              />
            </ShowStyle>
            <AdvantageTitleWrapper wdth={isDesktopScreen ? "" : ""}>
              <TransformText isAnimated={isReady} delay={0.6} height={48}>
                <AdvantageTitle>Life </AdvantageTitle>
              </TransformText>
              <TransformText isAnimated={isReady} delay={0.7} height={48}>
                <AdvantageColorTitle margin={"0px 7px"} color="#eb0075">
                  <AdvantageTag isAnimated={scrollPointer === end}>
                    with
                  </AdvantageTag>
                  <AdvantageTempTag isHide={scrollPointer === end}>
                    out
                  </AdvantageTempTag>
                </AdvantageColorTitle>{" "}
              </TransformText>
              <AdvantageTransformTitle isAnimated={scrollPointer === end}>
                <TransformText isAnimated={isReady} delay={0.8} height={48}>
                  <AdvantageTitle>Asquad</AdvantageTitle>{" "}
                </TransformText>
              </AdvantageTransformTitle>
              <AdvantageTransformTitle isAnimated={scrollPointer === end}>
                <ShowStyle isAnimated={isReady} delay={2}>
                  <QuestionTitle
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                    onClick={() => setActiveFirst(!activeFirst)}
                  >
                    {isDesktopScreen ? "How it works ?" : "?"}
                  </QuestionTitle>
                </ShowStyle>
              </AdvantageTransformTitle>
            </AdvantageTitleWrapper>
          </SwitchWrapper>
        </AnimationWrapperForHide>
        <div>
          <AdvantageContext.Provider value={valueMemo}>
            <AdvantageInfoWrapper>
              <Advantage
                start={3}
                text={["CTO", "Corezoid Developer (or CTO)"]}
                title="1 x"
                isHide={isLeaveScreen}
              />
              <Advantage
                isHide={isLeaveScreen}
                text={["Back-End dev"]}
                title="1 x"
                diffDelay={0.3}
              />
              <Advantage
                isHide={
                  (isReadyAnimationStart && scrollPointer !== start) ||
                  isLeaveScreen
                }
                text={["Product Owner"]}
                title="1 x"
                diffDelay={0.6}
              />
              <Advantage
                isHide={
                  (isReadyAnimationStart && scrollPointer !== start) ||
                  isLeaveScreen
                }
                text={["QA"]}
                title="1 x"
                diffDelay={0.9}
              />
            </AdvantageInfoWrapper>
          </AdvantageContext.Provider>
        </div>

        {scrollPointer === 3 && (
          <AdvantageModalWrapper
          // style={
          //   activeFirst
          //     ? { transform: "translateX(0)" }
          //     : { transform: "translateX(100%)" }
          // }
          >
            <AdvantageColorWrapper bg="#EB0075" isHovering={isHovering}>
              {/* <DemoModalContent> */}
              <AdvantageModalHeader>
                <AdvantageListTitle>How it works ?</AdvantageListTitle>
                <img
                  src={closeBtn}
                  onClick={() => setActiveFirst(false)}
                  alt="img"
                />
              </AdvantageModalHeader>
              <AdvantageModalList>
                <AdvantageListItem>
                  New Integration or Change Request comes in
                </AdvantageListItem>
                <AdvantageListItem>
                  The Product Owner learns the Request
                </AdvantageListItem>
                <AdvantageListItem>
                  The Product Owner describes the Request to a CTO
                </AdvantageListItem>
                <AdvantageListItem>CTO assigns the Task</AdvantageListItem>
                <AdvantageListItem>
                  The developer starts to work on the Task
                </AdvantageListItem>
                <AdvantageListItem>
                  Release to a Test Environment
                </AdvantageListItem>
                <AdvantageListItem>The QA tests the Change</AdvantageListItem>
                <AdvantageListItem>Review</AdvantageListItem>
                <AdvantageListItem>Release to a Production</AdvantageListItem>
                <AdvantageListItem>
                  <span>Shit</span> happens. It doesn't work for some reason
                </AdvantageListItem>
                <AdvantageListItem>
                  The CTO learns the issue. Discussion in progress
                </AdvantageListItem>
                <AdvantageListItem>
                  The developer returns the Task
                </AdvantageListItem>
                <AdvantageListItem>
                  Release to the Test Environment
                </AdvantageListItem>
                <AdvantageListItem>QA tests Change</AdvantageListItem>
                <AdvantageListItem>Review</AdvantageListItem>
                <AdvantageListItem>Release to the Production</AdvantageListItem>
                <AdvantageListItem>Finally, it works</AdvantageListItem>
              </AdvantageModalList>
              {/* </DemoModalContent> */}
            </AdvantageColorWrapper>
          </AdvantageModalWrapper>
        )}

        {scrollPointer === 4 && (
          <AdvantageModalWrapper
          // style={
          //   activeFirst
          //     ? { transform: "translateX(0)" }
          //     : { transform: "translateX(100%)" }
          // }
          >
            <AdvantageColorWrapper bg="#0FCA87" isHovering={isHovering}>
              {/* <DemoModalContent> */}
              <AdvantageModalHeader>
                <AdvantageListTitle>How it works ?</AdvantageListTitle>
                {!isDesktopScreen && (
                  <img
                    src={closeBtn}
                    onClick={() => setActiveFirst(false)}
                    alt="img"
                  />
                )}
              </AdvantageModalHeader>
              <AdvantageModalListSecond>
                <AdvantageListItem>
                  Integration Request comes in
                </AdvantageListItem>
                <AdvantageListItem>
                  The developer implements the Request for integration in 1 day
                </AdvantageListItem>
                <AdvantageListBetweenTitle>or</AdvantageListBetweenTitle>
                <AdvantageListItem>
                  Business Change Request comes in
                </AdvantageListItem>
                <AdvantageListItem>
                  The developer implements the Change Request in 30 min
                </AdvantageListItem>
              </AdvantageModalListSecond>
              {/* </DemoModalContent> */}
            </AdvantageColorWrapper>
          </AdvantageModalWrapper>
        )}
      </SectionContainer>
    </Container>
  );
};

export default SectionAdvantages;
