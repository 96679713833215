import styled, { css, keyframes } from "styled-components";

const isShownAnimation = keyframes`
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
`;

const isHideAnimation = keyframes`
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
`;

export const ButtonContainer = styled.div`
  margin: auto auto 48px;
  display: flex;
  justify-content: center;
  @media (min-width: 768px) {
    margin: auto auto 100px;
  }
`;

export const ButtonDemo = styled.div`
  position: absolute;
  cursor: pointer;
  background: var(--mainBlack);
  border: 1px solid #101820;
  padding: 8px 66px;
  color: #fff;
  border-radius: 30px;
  font-size: 18px;
  top: 80%;
  transition: ease 0.3s;
  opacity: 0;

  @media (min-width: 768px) {
    font-size: 24px;
    padding: 16px 161px;
  }
  @media (min-width: 1366px) {
    top: 85%;
    background: none;
    color: var(--mainBlack);
  }

  @media (min-width: 1420px) {
    font-size: 24px;
    padding: 16px 161px;

    &:hover {
      background: var(--mainBlack);
      color: #fff;
      font-family: var(--rosaFont);
    }
  }

  ${({ isAnimated, delay }) =>
    isAnimated &&
    css`
      animation: ${isShownAnimation} 1.5s ease ${delay}s;
      animation-fill-mode: forwards;
    `}

  ${({ isHide, isAnimated }) =>
    isAnimated &&
    isHide &&
    css`
      animation: ${isHideAnimation} 0.6s ease;
      animation-fill-mode: forwards;
    `}
`;

export const AnimationImgWrapperMob = styled.div`
  opacity: 0;
  z-index: -1;
  ${({ delay }) =>
    css`
      animation: ${isShownAnimation} 1.5s ease ${delay}s;
      animation-fill-mode: forwards;
    `}
`;
