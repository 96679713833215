/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext, useRef } from "react";
import { Context } from "../../Container";
import { CONFIG } from "../../../services/constants";
import { SectionContainer } from "../../../style";
import { SectionQuotesContainer } from "./style";
import { Container } from "../../../style";
import { useMediaQuery } from "react-responsive";
import Header from "../../../components/Header";
import {
  InternalsTextWrapper,
  SecondMainTitle,
  SubTitleGraySpan,
  InternalsContentWrapper,
  InternalsWrapper,
  SubTitleGray,
} from "../../../style";

const SectionInternals = ({ index, value }) => {
  const context = useContext(Context);
  const {
    isLeaving,
    scrollPointer,
    isPossibleScrolling,
    setIsLeaving,
    setIsPossibleScrolling,
  } = context;
  const isMobileScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const isTableScreen = useMediaQuery({ query: "(min-width: 768px)" });
  const isDesktopScreen = useMediaQuery({ query: "(min-width: 1366px)" });

  const isBigDesktopScreen = useMediaQuery({
    query: "(min-width: 1920px)",
  });

  let subTitleFs = "";

  let subTitleSpanFs = "";
  let mr = "";
  if (isMobileScreen) {
    subTitleFs = "18";
    subTitleSpanFs = "28";
  }

  if (isTableScreen) {
    subTitleFs = "32";
  }

  if (isDesktopScreen) {
    subTitleFs = 36;

    mr = 10;
  }

  if (isBigDesktopScreen) {
    mr = 10;
  }

  // let mb = "";
  // let widthImg = "";
  // let heightImg = "";
  // let positionImg = "28px 0px 0px";
  // let firstBlock = 190;
  // let secondBlock = 164;
  // if (isMobileScreen) {
  //   mb = "158";
  // }

  const refQuotesTextFirst = useRef();
  const refQuotesTextSecond = useRef();

  const [isReadyAnimationStart, setIsReadyAnimationStart] = useState(false);
  const [isLeaveReady, setIsLeaveReady] = useState(false);
  const [currentSizes, setCurrentSizes] = useState({
    first: {
      height: 0,
      width: 0,
    },
    second: {
      height: 0,
      width: 0,
    },
  });

  const start = CONFIG[index].first;
  const end = CONFIG[index].last || start;
  const isBetween = scrollPointer >= start && scrollPointer <= end;

  useEffect(() => {
    if (refQuotesTextFirst && refQuotesTextSecond) {
      setCurrentSizes({
        first: {
          height: refQuotesTextFirst.current.clientHeight,
          width: refQuotesTextFirst.current.clientWidth,
        },
      });
    }
  }, [refQuotesTextFirst, refQuotesTextSecond]);

  useEffect(() => {
    if (isLeaving === "ready" && isBetween) {
      setIsLeaveReady(true);
    } else {
      setTimeout(() => {
        setIsLeaveReady(false);
      }, 600);
    }
  }, [isLeaving, isBetween]);

  useEffect(() => {
    if (isBetween) {
      setTimeout(() => {
        setIsReadyAnimationStart(true);
      }, 1000);
    } else {
      setIsReadyAnimationStart(false);
    }
  }, [isBetween]);

  useEffect(() => {
    if (isBetween && isLeaveReady) {
      setTimeout(() => {
        setIsLeaving("finish");
      }, 600);
    }

    if (isBetween && !isPossibleScrolling) {
      setTimeout(() => {
        setIsPossibleScrolling(true);
      }, 1600);
    }
  }, [isLeaveReady]);

  return (
    <SectionContainer>
      {scrollPointer >= 1 && <Header value={value} />}
      <Container>
        <SectionQuotesContainer>
          <InternalsContentWrapper ref={refQuotesTextFirst}>
            <InternalsWrapper>
              <InternalsTextWrapper>
                <SecondMainTitle mb="0">
                  Flexibility
                  {!isDesktopScreen && (
                    <SubTitleGraySpan mr={mr} size={subTitleSpanFs}>
                      &
                    </SubTitleGraySpan>
                  )}
                </SecondMainTitle>

                <SecondMainTitle>
                  {isDesktopScreen && (
                    <SubTitleGraySpan mr={mr} size={subTitleSpanFs}>
                      &
                    </SubTitleGraySpan>
                  )}{" "}
                  Customization
                </SecondMainTitle>
              </InternalsTextWrapper>
              <SubTitleGray size={subTitleFs}>
                Agility is critical in the era of changes
              </SubTitleGray>
            </InternalsWrapper>
          </InternalsContentWrapper>
        </SectionQuotesContainer>
      </Container>
    </SectionContainer>
  );
};

export default SectionInternals;
