// import styled, { css, keyframes } from "styled-components";

// const isShowAnimate = keyframes`
//     0% {
//         opacity: 0;
//         transform: translateY(15px);
//         line-height: 42px;
//     }

//     100% {
//         opacity: 1;
//         transform: translateY(0);
//         line-height: 26px;
//     }
// `;

// const isShow = keyframes`
//   0% {
//     opacity: 0
//   }

//   100% {
//     opacity: 1;
//   }
// `;

// const isHideAnimate = keyframes`
//   0% {
//     opacity: 1
//   }

//   100% {
//     opacity: 0;
//   }
// `;

// export const SectionQuotesContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   height: 100vh;
//   padding: 65px 0 131px;
//   position: relative;
// `;

// export const QuotesTextWrapper = styled.div`
//   display: flex;
//   background: #ffffff;
//   max-width: 733px;
//   width: 0;
//   height: 0;
//   text-align: left;
//   padding: 0;
//   opacity: 0;
//   transition: width 1s, height 1s, padding 1s;

//   span {
//     opacity: 0;
//     filter: blur(4px);
//   }

//   ${({ position }) => {
//     if (position === "bottom") {
//       return css`
//         border-radius: 30px 30px 0 30px;
//         margin: auto 0 0 auto;

//         span {
//           /* right: 62px; */
//         }
//       `;
//     }

//     return css`
//       margin: 0 auto auto 0;
//       border-radius: 30px 30px 30px 0px;
//     `;
//   }}

//   ${({ isAnimated, newHeight, newWidth }) =>
//     isAnimated &&
//     newWidth &&
//     newHeight &&
//     css`
//      padding: 16px;
//       width: ${newWidth + 124}px;
//       height: ${newHeight + 124}px;
//       animation: ${isShow} 0.3s ease;
//       animation-fill-mode: forwards;
//             @media (width: 768px) {
//    padding: 62px;
//   }

//     animation-play-state: ${({ isAnimated }) =>
//       isAnimated ? "running" : "paused"};

//         ${({ delay }) =>
//           delay &&
//           `
//       animation-delay: ${delay}s;
//       `};

//       span {
//         /* animation: ${isShowAnimate} 1s ease 1.2s;
//         animation-fill-mode: forwards; */
//         transform: scale(0.94);
//         animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
//         font-size: 24px;
//         line-height: 26px;

//       }

//   span:nth-child(1) {
//     animation:  fade-in  0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
//   }

//   span:nth-child(2) {
//     animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
//   }
//   span:nth-child(3) {
//   animation: fade-in 0.8s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(4) {
//   animation: fade-in 0.8s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(5) {
//   animation: fade-in 0.8s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(6) {
//   animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(7) {
//   animation: fade-in 0.8s 0.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(8) {
//   animation: fade-in 0.8s 0.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(9) {
//   animation: fade-in 0.8s 0.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(10) {
//   animation: fade-in 0.8s 1s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(11) {
//   animation: fade-in 0.8s 1.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(12) {
//   animation: fade-in 0.8s 1.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(13) {
//   animation: fade-in 0.8s 1.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(14) {
//   animation: fade-in 0.8s 1.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(15) {
//   animation: fade-in 0.8s 1.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(16) {
//   animation: fade-in 0.8s 1.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(17) {
//   animation: fade-in 0.8s 1.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(18) {
//   animation: fade-in 0.8s 1.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }
// span:nth-child(19) {
//   animation: fade-in 0.8s 1.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }
// span:nth-child(20) {
//   animation: fade-in 0.8s 2s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(21) {
//   animation: fade-in 0.8s 2.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(22) {
//   animation: fade-in 0.8s 2.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(23) {
//   animation: fade-in 0.8s 2.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(24) {
//   animation: fade-in 0.8s 2.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(25) {
//   animation: fade-in 0.8s 2.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(26) {
//   animation: fade-in 0.8s 2.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(27) {
//   animation: fade-in 0.8s 2.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(28) {
//   animation: fade-in 0.8s 2.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(29) {
//   animation: fade-in 0.8s 2.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(30) {
//   animation: fade-in 0.8s 3s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }
// span:nth-child(31) {
//   animation: fade-in 0.8s 3.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }
//   @keyframes fade-in {
//   100% {
//     opacity: 1;
//     filter: blur(0);
//   }
//     `}

//   ${({ isAnimated, isHide }) =>
//     isAnimated &&
//     isHide &&
//     css`
//       animation: ${isHideAnimate} 1s ease;
//       animation-fill-mode: forwards;
//     `}
// `;

// export const QuotesTextWrapperSecond = styled.div`
//   display: flex;
//   background: #ffffff;
//   max-width: 733px;
//   width: 0;
//   height: 0;
//   text-align: left;
//   padding: 0;
//   opacity: 0;
//   transition: width 1s, height 1s, padding 1s;

//   span {
//     opacity: 0;
//     filter: blur(4px);
//   }

//   ${({ position }) => {
//     if (position === "bottom") {
//       return css`
//         border-radius: 30px 30px 0 30px;
//         margin: auto 0 0 auto;

//         span {
//           /* right: 62px; */
//         }
//       `;
//     }

//     return css`
//       margin: 0 auto auto 0;
//       border-radius: 30px 30px 30px 0px;
//     `;
//   }}

//   ${({ isAnimated, newHeight, newWidth }) =>
//     isAnimated &&
//     newWidth &&
//     newHeight &&
//     css`
//      padding: 16px;
//       width: ${newWidth + 124}px;
//       height: ${newHeight + 124}px;
//       animation: ${isShow} 0.3s ease;
//       animation-fill-mode: forwards;
//             @media (width: 768px) {
//    padding: 62px;
//   }

//     animation-play-state: ${({ isAnimated }) =>
//       isAnimated ? "running" : "paused"};

//         ${({ delay }) =>
//           delay &&
//           `
//       animation-delay: ${delay}s;
//       `};

//       span {
//         /* animation: ${isShowAnimate} 1s ease 1.2s;
//         animation-fill-mode: forwards; */
//         transform: scale(0.94);
//         animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
//         font-size: 24px;
//         line-height: 26px;
//              @media (width: 1366px) {
//               font-size: 24px;
//   }
//       }

//   span:nth-child(1) {
//     animation:  fade-in  0.8s 3.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
//   }

//   span:nth-child(2) {
//     animation: fade-in 0.8s 3.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
//   }
//   span:nth-child(3) {
//   animation: fade-in 0.8s 3.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(4) {
//   animation: fade-in 0.8s 3.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(5) {
//   animation: fade-in 0.8s 3.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(6) {
//   animation: fade-in 0.8s 3.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(7) {
//   animation: fade-in 0.8s 4s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(8) {
//   animation: fade-in 0.8s 4.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(9) {
//   animation: fade-in 0.8s 4.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(10) {
//   animation: fade-in 0.8s 4.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(11) {
//   animation: fade-in 0.8s 4.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(12) {
//   animation: fade-in 0.8s 4.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(13) {
//   animation: fade-in 0.8s 4.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(14) {
//   animation: fade-in 0.8s 4.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(15) {
//   animation: fade-in 0.8s 4.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(16) {
//   animation: fade-in 0.8s 4.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(17) {
//   animation: fade-in 0.8s 5s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

// span:nth-child(18) {
//   animation: fade-in 0.8s 5.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }
// span:nth-child(19) {
//   animation: fade-in 0.8s 5.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
// }

//   @keyframes fade-in {
//   100% {
//     opacity: 1;
//     filter: blur(0);
//   }
//     `}

//   ${({ isAnimated, isHide }) =>
//     isAnimated &&
//     isHide &&
//     css`
//       animation: ${isHideAnimate} 1s ease;
//       animation-fill-mode: forwards;
//     `}
// `;

// export const QuotesText = styled.span`
//   font-family: var(--mainFont);
//   font-style: normal;
//   font-weight: 400;
//   font-size: 24px;
//   line-height: 26px;
//   color: #101820;
//   position: absolute;
//   max-width: 610px;
//   width: 100%;
// `;

// export const QuotesTextAuthor = styled(QuotesText)`
//   text-align: right;
//   font-weight: 500;
//   margin-top: 48px;
//   position: relative;
//   display: block;
// `;

import styled, { css, keyframes } from "styled-components";

const isShowAnimate = keyframes`
    0% {
        opacity: 0;
        transform: translateY(15px);
        line-height: 42px;
    }

    100% {
        opacity: 1;
        transform: translateY(0);
        line-height: 26px;
    }
`;

const isShow = keyframes`
  0% {
    opacity: 0
  }

  100% {
    opacity: 1;
  }
`;

const isHideAnimate = keyframes`
  0% {
    opacity: 1
  }

  100% {
    opacity: 0;
  }
`;

export const SectionQuotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* padding: 32px 0; */
  /* margin-top: 32px; */
  /* padding: 65px 0 131px; */
  position: relative;
`;

export const QuotesTextWrapper = styled.div`
  display: flex;

  margin: 0 auto;
  width: 0;
  height: ${(props) => props.height}px;
  text-align: left;
  padding: 0;
  opacity: 0;

  transition: width 1s, height 1s, padding 1s;

  span {
    opacity: 0;
    transform: translateY(15px);
  }

  ${({ position }) => {
    if (position === "bottom") {
      return css`
        border-radius: 30px 30px 0 30px;
        margin-left: auto;

        /* span {
          right: 62px;
        } */
      `;
    }

    return css`
      margin-bottom: 158px;
      margin-top: 54px;
      border-radius: 30px 30px 30px 0px;
      @media (min-width: 768px) {
        margin-top: 100px;
        margin-bottom: 250px;
      }
      @media (min-width: 1366px) {
        margin-top: 64px;
        margin-bottom: 180px;
      }
      @media (min-width: 1400px) and (min-height: 900px) {
        margin-top: 238px;
        margin-left: 40%;
      }
    `;
  }}

  ${({ isAnimated, newHeight, newWidth }) =>
    isAnimated &&
    newWidth &&
    newHeight &&
    css`
      padding: 16px;
      /* width: ${newWidth + 124}px; */
      width: 236px;
      /* height: ${newHeight + 124}px; */
      /* height: ${(props) => props.height}px; */
      animation: ${isShow} 0.3s ease;
      animation-fill-mode: forwards;
      @media (min-width: 768px) {
        width: 441px;
        padding: 32px;
      }
      @media (min-width: 1366px) {
        width: 693px;
        padding: 32px;
      }

      span {
        animation: ${isShowAnimate} 1s ease 1.2s;
        animation-fill-mode: forwards;
      }
    `}

${({ isAnimated, isHide }) =>
    isAnimated &&
    isHide &&
    css`
      animation: ${isHideAnimate} 1s ease;
      animation-fill-mode: forwards;
    `}
`;

export const QuotesText = styled.span`
  font-family: var(--mainFont);
  font-style: normal;
  font-weight: 400;

  color: #101820;
  & > p {
    font-size: 16px;
    line-height: 19px;
    @media (min-width: 768px) {
      font-size: 24px;
      line-height: 26px;
    }
  }

  & > p > b {
    font-family: var(--mediumFont);
  }
`;

export const QuotesTextAuthor = styled(QuotesText)`
  text-align: right;
  font-weight: 500;
  margin-top: 20px;
  position: relative;
  font-family: var(--mediumFont);
  display: block;
  @media (min-width: 768px) {
    margin-top: 48px;
    font-size: 24px;
    line-height: 26px;
  }
`;
