import { useState, useEffect, useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { Context } from "../../Container";
import { Global3 } from "./style";
import src from "../../../img/global3.png";

const GlobalBlock1 = () => {
  const isDesktopScreen = useMediaQuery({ query: "(min-width: 350px)" });

  const context = useContext(Context);
  const { isLeaving, scrollPointer, direction, setIsLoadedImages } = context;

  const [isReadyAnimationStart, setIsReadyAnimationStart] = useState(false);
  const [animationNumber, setAnimationNumber] = useState(null);

  useEffect(() => {
    if (scrollPointer !== null && scrollPointer >= 0) {
      setTimeout(() => {
        setIsReadyAnimationStart(true);
      }, 0);
    }
  }, [scrollPointer]);

  useEffect(() => {
    if (scrollPointer === 3 || scrollPointer === 4) {
      setAnimationNumber("section-3");
    }

    if (
      (scrollPointer === 5 && isLeaving === "finish" && direction === 1) ||
      scrollPointer === 2
    ) {
      setAnimationNumber("leaveSection-3");
    }
  }, [scrollPointer, isLeaving, direction]);

  return (
    <Global3 isShow={isReadyAnimationStart} isAnimated={animationNumber}>
      {isDesktopScreen && (
        <img
          loading="lazy"
          width="1200"
          height="1200"
          src={src}
          alt="hero"
          onLoad={() => setIsLoadedImages((prev) => prev + 1)}
        />
      )}
    </Global3>
  );
};

export default GlobalBlock1;
