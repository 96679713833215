import styled, { css, keyframes } from "styled-components";

const spinnerAnimation = keyframes`
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
`;

const isHideAnimation = keyframes`
    0% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
`;

const isHideCss = css`
  /* animation: ${isHideAnimation} 1s ease;
  animation-fill-mode: forwards; */
  opacity: 0;
`;

export const PreloaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  background-color: #e5e5e5;

  ${({ isHide }) => isHide && isHideCss}
`;

export const PreloaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 20px auto auto;
  @media (min-width: 768px) {
    margin: 24px auto auto;
  }
  @media (min-width: 1366px) {
    margin: 52px auto auto;
  }
`;

export const PreloaderText = styled.div`
  padding-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isAnimated }) =>
    isAnimated &&
    css`
      animation: ${isHideAnimation} 1s ease;
      animation-fill-mode: forwards;
    `}
  @media (min-width: 1366px) {
    padding-top: 15px;
  }
`;

export const PreloaderTextCounter = styled.span`
  margin-left: 39px;
  font-family: var(--mainFont);
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 55px;
  width: 50px;
  color: #101820;
`;

export const PreloaderTextSub = styled(PreloaderTextCounter)`
  margin-left: 0;
  color: #101820;
`;

export const PreloaderSpinner = styled.div`
  margin-top: 18px;
  img {
    width: 100%;
    height: 100%;
    animation: ${spinnerAnimation} 1s linear;
    animation-iteration-count: 3;
  }
  @media (min-width: 768px) {
    margin-left: 4px;
  }
  @media (min-width: 1366px) {
    margin-left: 4px;
  }
`;
