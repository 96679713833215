import React from "react";
import AsquadLogo from "../img/AsquadLogo";
import {
  TextWrapper,
  HeadTitle,
  IntroTitle,
  Container,
  IntroLogoWrapper,
} from "../style";
import {
  ShowStyle,
  AnimationWrapperForHide,
  TransformText,
  TransformTextWrapper,
  TransformShowText,
} from "../styleAnimation";
import { useMediaQuery } from "react-responsive";

const Intro = ({ isReadyAnimationStart, isHide }) => {
  const isMobileScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const isTableScreen = useMediaQuery({ query: "(min-width: 768px)" });
  const isDesktopScreen = useMediaQuery({ query: "(min-width: 1366px)" });
  const isBigDesktopScreen = useMediaQuery({ query: "(min-width: 1920px)" });

  let marginTop = "";
  let fontSize = "16";
  let widthBlock = "";
  let positionCenter = "";
  let heightBlock = "";
  if (isMobileScreen) {
    widthBlock = 350;
    positionCenter = "0 auto";
    marginTop = 32;
  }
  if (isTableScreen) {
    marginTop = 32;
    widthBlock = 670;
    fontSize = "64";
    positionCenter = "0 auto";
  }
  if (isDesktopScreen) {
    widthBlock = 0;
    marginTop = 0;
    positionCenter = "";
    heightBlock = 80;
  }
  if (isBigDesktopScreen) {
    // marginTop = "64";

    fontSize = "96";
  }

  return (
    <Container>
      <AnimationWrapperForHide
        isHide={isHide}
        isAnimated={isReadyAnimationStart}
      >
        <TextWrapper mt={marginTop}>
          <ShowStyle isAnimated={isReadyAnimationStart}>
            <IntroLogoWrapper isDesktopScreen={isDesktopScreen}>
              <AsquadLogo />
            </IntroLogoWrapper>
          </ShowStyle>
          <TransformTextWrapper
            height={heightBlock}
            widthBlock={widthBlock}
            center={positionCenter}
          >
            <TransformText
              isAnimated={isReadyAnimationStart}
              delay={1.2}
              height={+fontSize + 14}
              center={true}
            >
              <HeadTitle mr={15}>Low-Code</HeadTitle>
            </TransformText>
            <TransformText
              isAnimated={isReadyAnimationStart}
              delay={1.2}
              // height={+fontSize + 14}
            >
              <HeadTitle mr={15}>Payment</HeadTitle>
            </TransformText>
            <TransformText
              isAnimated={isReadyAnimationStart}
              delay={1.3}
              height={+fontSize}
            >
              <HeadTitle>Platform</HeadTitle>
            </TransformText>
          </TransformTextWrapper>
          <TransformShowText isAnimated={isReadyAnimationStart}>
            <IntroTitle>
              In the world of online payments, we've got you covered
            </IntroTitle>
          </TransformShowText>
        </TextWrapper>
      </AnimationWrapperForHide>
    </Container>
  );
};

export default Intro;
