import styled, { css, keyframes } from "styled-components";

const isShowAnimate = keyframes`
    0% {
        opacity: 0;
        transform: translateY(15px);
        line-height: 42px;
    }

    100% {
        opacity: 1;
        transform: translateY(0);
        line-height: 26px;
    }
`;

const isShow = keyframes`
  0% {
    opacity: 0
  }

  100% {
    opacity: 1;
  }
`;

const isHideAnimate = keyframes`
  0% {
    opacity: 1
  }

  100% {
    opacity: 0;
  }
`;

export const SectionQuotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* padding: 32px 0; */
  /* margin-top: 32px; */
  /* padding: 65px 0 131px; */
  position: relative;
`;

export const QuotesTextWrapper = styled.div`
  display: flex;
  background: #ffffff;
  width: 0;
  height: ${(props) => props.height}px;
  text-align: left;
  padding: 0;
  opacity: 0;

  transition: width 1s, height 1s, padding 1s;

  span {
    opacity: 0;
    transform: translateY(15px);
  }

  ${({ position }) => {
    if (position === "bottom") {
      return css`
        border-radius: 30px 30px 0 30px;
        margin-left: auto;

        /* span {
          right: 62px;
        } */
      `;
    }

    return css`
      margin-bottom: 158px;
      margin-top: 54px;
      border-radius: 30px 30px 30px 0px;
      @media (min-width: 768px) {
        margin-top: 100px;
        margin-bottom: 250px;
      }
      @media (min-width: 1366px) {
        margin-top: 64px;
        margin-bottom: 180px;
      }
      @media (min-width: 1400px) and (min-height: 900px) {
        margin-top: 138px;
      }
    `;
  }}

  ${({ isAnimated, newHeight, newWidth }) =>
    isAnimated &&
    newWidth &&
    newHeight &&
    css`
      padding: 16px;
      /* width: ${newWidth + 124}px; */
      width: 236px;
      /* height: ${newHeight + 124}px; */
      /* height: ${(props) => props.height}px; */
      animation: ${isShow} 0.3s ease;
      animation-fill-mode: forwards;
      @media (min-width: 768px) {
        width: 441px;
        padding: 32px;
      }
      @media (min-width: 1366px) {
        width: 673px;
        padding: 32px;
      }

      span {
        animation: ${isShowAnimate} 1s ease 1.2s;
        animation-fill-mode: forwards;
      }
    `}

${({ isAnimated, isHide }) =>
    isAnimated &&
    isHide &&
    css`
      animation: ${isHideAnimate} 1s ease;
      animation-fill-mode: forwards;
    `}
`;

export const QuotesText = styled.span`
  font-family: var(--mainFont);
  font-style: normal;
  font-weight: 400;

  color: #101820;
  & > p {
    font-size: 16px;
    line-height: 19px;
    @media (min-width: 768px) {
      font-size: 24px;
      line-height: 26px;
    }
  }

  & > p > b {
    font-family: var(--mediumFont);
  }
`;

export const QuotesTextAuthor = styled(QuotesText)`
  text-align: right;
  font-weight: 500;
  margin-top: 20px;
  position: relative;
  font-family: var(--mediumFont);
  display: block;
  @media (min-width: 768px) {
    margin-top: 15px;
    font-size: 24px;
    line-height: 26px;
  }
`;
