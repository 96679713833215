/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext, createContext, useMemo } from "react";
import { Context } from "../../Container";
import { CONFIG } from "../../../services/constants";
import { SectionContainer } from "../../../style";
import { Container } from "../../../style";
import Header from "../../../components/Header";
import { TransformShowBlock } from "./style";
import { AdvantageInfoWrapper } from "../../../style";
import Numbers from "./Numbers";
import Numbers2 from "./Numbers2";
import Numbers3 from "./Numbers3";
export const NumberContext = createContext({
  isReadyAnimationStart: false,
  isBetween: false,
  scrollPointer: null,
});
const SectionNumbers = ({ index, value }) => {
  const context = useContext(Context);
  const {
    isLeaving,
    scrollPointer,
    isPossibleScrolling,
    setIsLeaving,
    setIsPossibleScrolling,
  } = context;

  const [isReadyAnimationStart, setIsReadyAnimationStart] = useState(false);
  const [isLeaveReady, setIsLeaveReady] = useState(false);

  const start = CONFIG[index].first;
  const end = CONFIG[index].last || start;
  const isBetween = scrollPointer >= start && scrollPointer <= end;

  const valueMemo = useMemo(
    () => ({
      isReadyAnimationStart,
      isBetween,
      scrollPointer,
    }),
    [isReadyAnimationStart, isBetween, scrollPointer]
  );

  useEffect(() => {
    if (isLeaving === "ready" && isBetween) {
      setIsLeaveReady(true);
    } else {
      setTimeout(() => {
        setIsLeaveReady(false);
      }, 600);
    }
  }, [isLeaving, isBetween]);

  useEffect(() => {
    if (isBetween) {
      setTimeout(() => {
        setIsReadyAnimationStart(true);
      }, 1000);
    } else {
      setIsReadyAnimationStart(false);
    }
  }, [isBetween]);

  useEffect(() => {
    if (isBetween && isLeaveReady) {
      setTimeout(() => {
        setIsLeaving("finish");
      }, 1200);
    }

    if (isBetween && !isPossibleScrolling) {
      setTimeout(() => {
        setIsPossibleScrolling(true);
      }, 1600);
    }
  }, [isLeaveReady]);

  return (
    <SectionContainer>
      {scrollPointer >= 1 && <Header value={value} />}
      <Container>
        <div>
          <NumberContext.Provider value={valueMemo}>
            <TransformShowBlock isAnimated={isReadyAnimationStart}>
              <AdvantageInfoWrapper>
                <Numbers index={index} text="" title="" diffDelay={0.6} />
                <Numbers2 index={index} text="" title="" diffDelay={0.6} />
                <Numbers3 index={index} text="" title="" diffDelay={0.6} />
              </AdvantageInfoWrapper>
            </TransformShowBlock>
          </NumberContext.Provider>
        </div>
      </Container>
    </SectionContainer>
  );
};

export default SectionNumbers;
