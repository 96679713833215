import styled, { css } from "styled-components";

const Section3 = css`
  opacity: 1;
  transition: opacity 2.6s linear 1s;
`;

const LeaveSection3 = css`
  opacity: 0;
  transition: opacity 1s linear;
`;

export const Global3 = styled.div`
  z-index: -1;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-20%, -50%);
  z-index: 1;
  opacity: 0;
  transition: opacity 1s;

  ${({ isAnimated, isShow }) => {
    if (isAnimated === "section-3" && isShow) {
      return Section3;
    }
    if (isAnimated === "leaveSection-3" && isShow) {
      return LeaveSection3;
    }
  }}
`;
