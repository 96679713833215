/* eslint-disable import/no-anonymous-default-export */
export const CONFIG = {
  0: {
    first: 0,
  },
  1: {
    first: 1,
  },
  2: {
    first: 2,
  },
  3: {
    first: 3,
    last: 4,
  },
  4: {
    first: 5,
    // last: 7,
  },
  5: {
    first: 6,
    // last: 7,
  },
  6: {
    first: 7,
    // last: 7,
  },
  7: {
    first: 8,
    // last: 7,
  },
  8: {
    first: 9,
    last: 10,
  },
  9: {
    first: 11,
  },
  10: {
    first: 12,
  },
  11: {
    first: 13,
    last: 15,
  },
  12: {
    first: 16,
  },
  13: {
    first: 17,
    last: 19,
  },
};

export default {
  CONFIG,
};
