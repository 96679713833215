/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { Context } from "../../Container";
import { CONFIG } from "../../../services/constants";
import { SectionContainer } from "../../../style";
import { Container } from "../../../style";
import { useMediaQuery } from "react-responsive";
import Header from "../../../components/Header";
import {
  ContentWrapperAdminPanel,
  TextWrapperAdminPanel,
  HeadTitle,
  SubTitleTextWrapper,
} from "../../../style";

const SectionAdminSecond = ({ index, value }) => {
  const context = useContext(Context);
  const {
    isLeaving,
    scrollPointer,
    isPossibleScrolling,
    setIsLeaving,
    setIsPossibleScrolling,
  } = context;

  const isMobileScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const isTableScreen = useMediaQuery({ query: "(min-width: 768px)" });
  const isDesktopScreen = useMediaQuery({ query: "(min-width: 1366px)" });
  const isBigDesktopScreen = useMediaQuery({ query: "(min-width: 1920px)" });

  let fs = "";
  let mb = "";
  let lh = "";
  let lhTxt = "";
  let fsSubTtl = "";
  if (isMobileScreen) {
    fs = "28";
    lh = "32";
  }

  if (isTableScreen) {
    fs = "48";
    mb = "32";
    lh = "56";
  }
  if (isDesktopScreen) {
    fs = "46";
    lhTxt = 36;
  }
  if (isBigDesktopScreen) {
    lh = "74";
    fs = "64";
    lhTxt = 42;
    fsSubTtl = 36;
  }

  const [isLeaveReady, setIsLeaveReady] = useState(false);

  const start = CONFIG[index].first;
  const end = CONFIG[index].last || start;
  const isBetween = scrollPointer >= start && scrollPointer <= end;
  const [isReadyAnimationStart, setIsReadyAnimationStart] = useState(false);
  useEffect(() => {
    if (isLeaving === "ready" && isBetween) {
      setIsLeaveReady(true);
    } else {
      setTimeout(() => {
        setIsLeaveReady(false);
      }, 600);
    }
  }, [isLeaving, isBetween]);

  useEffect(() => {
    if (isBetween) {
      setTimeout(() => {
        setIsReadyAnimationStart(true);
      }, 0);
    } else {
      setIsReadyAnimationStart(false);
    }
  }, [isBetween]);

  useEffect(() => {
    if (isBetween && isLeaveReady) {
      setTimeout(() => {
        setIsLeaving("finish");
      }, 600);
    }

    if (isBetween && !isPossibleScrolling) {
      setTimeout(() => {
        setIsPossibleScrolling(true);
      }, 1600);
    }
  }, [isLeaveReady]);

  return (
    <SectionContainer>
      {scrollPointer >= 1 && <Header value={value} />}
      <Container>
        <ContentWrapperAdminPanel>
          <TextWrapperAdminPanel>
            <HeadTitle mb={mb} fs={fs} lh={lh}>
              Juggle the Multiple Payment Options
            </HeadTitle>
            <SubTitleTextWrapper lh={lhTxt} fs={fsSubTtl}>
              Get an access to all of the most desired payment methods{" "}
              <span>within one integration.</span>
            </SubTitleTextWrapper>
          </TextWrapperAdminPanel>
        </ContentWrapperAdminPanel>
      </Container>
    </SectionContainer>
  );
};

export default SectionAdminSecond;
