import { useState, useEffect, useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { Context } from "../../Container";
import { Global1 } from "./style";
import heroImg from "../../../img/heroDeskImageFull.png";

const GlobalBlock1 = () => {
  const isSmallDeskScreen = useMediaQuery({ query: "(min-width: 1280px)" });
  const isMacBookDeskScreen = useMediaQuery({
    query: "(min-width: 1366px) and (min-height: 900px)",
  });

  const isBigDesktopScreen = useMediaQuery({
    query: "(min-width: 1920px) and (min-height: 1080px)",
  });

  let imgHeight = "1190";
  let mtImg = "32px";

  if (isSmallDeskScreen) {
    imgHeight = 680;
    mtImg = "60px";
  }

  if (isMacBookDeskScreen) {
    imgHeight = 980;
  }
  if (isBigDesktopScreen) {
    imgHeight = 950;
  }

  const context = useContext(Context);
  const { isLeaving, scrollPointer, direction, setIsLoadedImages } = context;

  const [isReadyAnimationStart, setIsReadyAnimationStart] = useState(false);
  const [animationNumber, setAnimationNumber] = useState(null);

  useEffect(() => {
    if (scrollPointer !== null && scrollPointer >= 0) {
      setTimeout(() => {
        setIsReadyAnimationStart(true);
      }, 0);
    }
  }, [scrollPointer]);

  useEffect(() => {
    if (scrollPointer === 0) {
      setAnimationNumber("section-0");
    }

    if (
      (scrollPointer === 0 && isLeaving === "finish" && direction === 1) ||
      scrollPointer === 1
    ) {
      setAnimationNumber("leave-0");
    }

    if (
      (scrollPointer === 1 && isLeaving === "finish" && direction === 1) ||
      scrollPointer === 2
    ) {
      setAnimationNumber("section-1");
    }
  }, [scrollPointer, isLeaving, direction]);

  return (
    <Global1 isShow={isReadyAnimationStart} isAnimated={animationNumber}>
      {isSmallDeskScreen && (
        <img
          width="913"
          height={imgHeight}
          style={{ display: "block", margin: "0 auto", marginTop: mtImg }}
          src={heroImg}
          alt="hero"
          onLoad={() => setIsLoadedImages((prev) => prev + 1)}
          loading="lazy"
        />
      )}
    </Global1>
  );
};

export default GlobalBlock1;
