/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef, useContext } from "react";
import { AdvantageContext } from "./index";
import { AdvantageColorTitle, AdvantageInfoBlock } from "../../../style";
import { TransformLeftToRight, TransformText } from "../../../styleAnimation";
import { useMediaQuery } from "react-responsive";

const Advantage = ({ isHide, text, title, diffDelay = 0, start }) => {
  const context = useContext(AdvantageContext);
  const { isReadyAnimationStart, isBetween, scrollPointer } = context;

  const [isReadyComponentAnimation, setIsReadyComponentAnimation] =
    useState(false);
  const [currentWidth, setCurrentWidth] = useState(0);
  const [currentText, setCurrentText] = useState("");
  const [semiHide, setSemiHide] = useState(false);
  const firstStepFirstRowRef = useRef();
  const isDesktopScreen = useMediaQuery({ query: "(min-width: 1366px)" });
  let secondTitleFs = 16;

  if (isDesktopScreen) {
    secondTitleFs = 48;
  }
  const isReady =
    isReadyAnimationStart && isBetween && isReadyComponentAnimation && !isHide;

  useEffect(() => {
    if (firstStepFirstRowRef && !semiHide) {
      setCurrentWidth(firstStepFirstRowRef.current.offsetWidth);
    }
  }, [firstStepFirstRowRef && firstStepFirstRowRef.current, semiHide]);

  useEffect(() => {
    if (start && Array.isArray(text) && isBetween) {
      setSemiHide(true);
    } else {
      setSemiHide(false);
    }
  }, [scrollPointer]);

  useEffect(() => {
    const currentPointer = start || scrollPointer;
    const diff = scrollPointer - currentPointer;
    const iteratorNumber = diff >= 0 ? diff : 0;

    if (semiHide) {
      setTimeout(() => {
        setCurrentText(null);
      }, 400);

      setTimeout(() => {
        setCurrentText(text[iteratorNumber]);
        setSemiHide(false);
      }, 1000);
    } else {
      setCurrentText(text[iteratorNumber]);
    }
  }, [semiHide]);

  useEffect(() => {
    if (isReadyAnimationStart && isBetween) {
      setTimeout(() => {
        setIsReadyComponentAnimation(true);
      }, +diffDelay * 1000 + 600);
    } else {
      setIsReadyComponentAnimation(false);
    }
  }, [isReadyAnimationStart, isBetween]);

  return (
    <TransformLeftToRight
      isHide={isHide}
      isAnimated={
        isReadyAnimationStart && isBetween && isReadyComponentAnimation
      }
      currentWidth={currentWidth}
      isSemiHide={semiHide}
    >
      <AdvantageInfoBlock ref={firstStepFirstRowRef}>
        {currentText && (
          <TransformText
            isAnimated={isReady}
            delay={0.6 + diffDelay}
            // height={58}
          >
            <span>
              <AdvantageColorTitle margin={"0px 7px 0px 0px"} color="#0062EB">
                {title}
              </AdvantageColorTitle>{" "}
            </span>
          </TransformText>
        )}

        {currentText && (
          <TransformText
            isAnimated={isReady}
            delay={0.7 + diffDelay}
            // height={58}
            fontSize={secondTitleFs}
          >
            <span>{currentText}</span>
          </TransformText>
        )}
      </AdvantageInfoBlock>
    </TransformLeftToRight>
  );
};

export default Advantage;
