import React from "react";
import { useLottie } from "lottie-react";
import { Container, AwesomeWrapper } from "../style";
import { useState, useEffect, useContext } from "react";
import awesomeAnimation from "../animation/awesome.json";
import { AnimationWrapperForHide } from "../styleAnimation";
import { Context } from "../views/Container";
import { Global2 } from "../views/Sections/SectionAwesome/style";
// import { useMediaQuery } from "react-responsive";

const Awesome = () => {
  const context = useContext(Context);
  const { isLeaving, scrollPointer, direction } = context;

  const [isReadyAnimationStart, setIsReadyAnimationStart] = useState(false);
  const [animationNumber, setAnimationNumber] = useState(null);

  useEffect(() => {
    if (scrollPointer !== null && scrollPointer === 8) {
      setTimeout(() => {
        setIsReadyAnimationStart(true);
      }, 0);
    }
  }, [scrollPointer]);

  useEffect(() => {
    if (scrollPointer === 8) {
      setAnimationNumber("Section-2");
    }
    if (
      (scrollPointer === 8 && isLeaving === "finish" && direction !== 0) ||
      scrollPointer !== 8
    ) {
      setAnimationNumber("leaveSection-2");
    }
  }, [scrollPointer, isLeaving, direction]);

  const options = {
    animationData: awesomeAnimation,
    loop: scrollPointer === 8,
    autoplay: scrollPointer === 8,
  };

  const { View } = useLottie(options);
  return (
    <Container>
      <AnimationWrapperForHide>
        <Global2 isShow={isReadyAnimationStart} isAnimated={animationNumber}>
          <AwesomeWrapper>{View}</AwesomeWrapper>
        </Global2>
      </AnimationWrapperForHide>
    </Container>
  );
};

export default Awesome;
