/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useRef, useEffect } from "react";
import {
  DemoModalWrapperActive,
  DemoModalContent,
  DemoModalHeader,
  DemoForm,
  DemoFormActionWrapper,
  DemoFormInputLabel,
  DemoFormInput,
  DemoFormTextArea,
  DemoFormTittle,
  DemoTitle,
  DemoFormInputWrapper,
  SubmitFormBtn,
  ActionRadioGroupWrapper,
  DemoModalContentWrapper,
  DemoButtonWrapper,
  StyledFormTelDropdown,
  DemoFormErrorTitle,
  DemoFormColumnWrapper,
  DemoFormInnerWrapper,
} from "../style";
import asquadSmallLogo from "../img/asquadFav.svg";
import closeBtn from "../img/closeBtn.svg";
import { useMediaQuery } from "react-responsive";
import Airtable from "airtable";

import "react-phone-input-2/lib/style.css";
const initialState = {
  type: "PSP",
  name: "",
  number: "",
  email: "",
  site: "",
  textarea: "",
};
Airtable.configure({
  endpointUrl: "https://api.airtable.com",
  apiKey: "keye8wQB5MkSnmj1P",
});
const base = Airtable.base("appVJrvFROYVci2TN");

const Demo = ({ active, setActive, firstIDLabel, secondIdLabel }) => {
  const isTableScreen = useMediaQuery({ query: "(min-width: 768px)" });
  const [form, setForm] = useState({ ...initialState });
  const [formErrors, setFormErrors] = useState({});

  const [reRender, setReRender] = useState(false);
  const errors = {};
  const regexMail = /^[a-z0-9.]{1,64}@[a-z0-9.]{1,64}$/i;
  const regexUrl =
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
  const inputHandler = ({ target }) => {
    const { name, value } = target;
    setForm((state) => ({ ...state, [name]: value }));

    setFormErrors(validate(form, name));
  };
  const sendDataTable = (form) => {
    const { type, name, number, email, site, textarea } = form;
    base("tbldmShYJRXJ2GU3Y").create(
      [
        {
          fields: {
            fldPUKbznzfRecEbH: type,
            fldUxvbJZ77B2EZGa: name,
            fldYNLXuRWKM2507N: number,
            fldxGFiiBsfDA6WB8: email,
            fldAeT0ZOIiYhUChj: site,
            fldVafVVyfEtXB2Qg: textarea,
            fldFz5jNpqGU8DzqB: "Landing",
          },
        },
      ],
      { typecast: true },
      function (err, records) {
        if (err) {
          return;
        }
        records.forEach(function (record) {
          record.getId();
        });
      }
    );
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setForm({ ...initialState });
    setFormErrors(submitVal(form));
    if (
      Object.keys(formErrors).length === 0 &&
      form.number !== "" &&
      form.email !== "" &&
      form.site !== ""
    ) {
      sendDataTable(form);
      setReRender(true);
    }
  };

  const submitVal = (val) => {
    if (!val.number) {
      errors.number = "Required field";
    }
    if (!val.email) {
      errors.email = "Required field";
    }

    if (!val.site) {
      errors.site = "Required field ";
    }
    return errors;
  };

  const validate = (val, name) => {
    if (!val.number && name === "number") {
      errors.number = "Required field";
    }
    if (!val.email && name === "email") {
      errors.email = "Required field";
    }
    if (!regexMail.test(val.email) && name === "email") {
      errors.email = "The email format is not valid";
    }
    if (!val.site && name === "site") {
      errors.site = "Required field ";
    }
    if (!regexUrl.test(val.site) && name === "site") {
      errors.site = "The website format is not valid";
    }
    return errors;
  };

  let data = "";
  const onClickHandler = (e) => {
    data = e;
    setForm((prev) => ({ ...prev, type: data }));
  };

  // let disabled = false;

  // if (form.type !== "" && form.number !== "" && form.email !== "" && form.name !== "") {
  //   disabled = true;
  // }

  const ref = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (active && ref.current && !ref.current.contains(e.target)) {
        setActive(false);
        setForm({ ...initialState });
        setFormErrors({});
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return (
    <DemoModalWrapperActive
      className="Modal"
      ref={ref}
      style={
        active
          ? { transform: "translateX(0)" }
          : { transform: "translateX(100%)" }
      }
    >
      <DemoModalContent className="Modal">
        <DemoModalHeader>
          {isTableScreen ? null : (
            <>
              <img src={asquadSmallLogo} alt="img" />{" "}
            </>
          )}
          {!isTableScreen ? (
            <img src={closeBtn} onClick={() => setActive(false)} alt="img" />
          ) : null}
        </DemoModalHeader>
        <DemoModalContentWrapper>
          {reRender ? (
            <>
              <DemoTitle> Thank you!</DemoTitle>
              <DemoTitle>Our manager will contact you shortly</DemoTitle>
              <SubmitFormBtn
                mt="375"
                bg="#F0F4F5"
                color="#101820"
                disabled={false}
                onClick={() => setActive(false)}
                type="button"
              >
                Close
              </SubmitFormBtn>
            </>
          ) : (
            <>
              <DemoTitle>Request a Demo</DemoTitle>
              <DemoFormTittle>Payment Solution needed:</DemoFormTittle>

              <DemoForm onSubmit={submitHandler} autoComplete="off">
                <DemoFormColumnWrapper>
                  <DemoFormActionWrapper>
                    <ActionRadioGroupWrapper mr={true}>
                      <input
                        onClick={() => onClickHandler("PSP")}
                        type="radio"
                        checked={form.type === "PSP"}
                        id={firstIDLabel}
                        name="type"
                        value={form.type}
                      />
                      <label htmlFor={firstIDLabel}>
                        Asquad as Payment Service Provider
                      </label>
                    </ActionRadioGroupWrapper>
                    <ActionRadioGroupWrapper ml={true}>
                      <input
                        onClick={() => onClickHandler("WL")}
                        checked={form.type === "WL"}
                        type="radio"
                        id={secondIdLabel}
                        name="type"
                        value={form.type}
                      />
                      <label htmlFor={secondIdLabel}>
                        Asquad as White Label Solution
                      </label>
                    </ActionRadioGroupWrapper>
                  </DemoFormActionWrapper>
                </DemoFormColumnWrapper>

                <DemoFormInputWrapper>
                  <DemoFormColumnWrapper change>
                    <DemoFormInnerWrapper>
                      <DemoFormInputLabel>Name</DemoFormInputLabel>
                      <DemoFormInput
                        onChange={inputHandler}
                        type="text"
                        value={form.name}
                        name="name"
                      />
                    </DemoFormInnerWrapper>
                    <DemoFormInnerWrapper>
                      <DemoFormInputLabel>Phone number</DemoFormInputLabel>
                      <StyledFormTelDropdown
                        country={"ua"}
                        enableSearch
                        searchPlaceholder="Search country"
                        value={form.number}
                        error={formErrors.number}
                        onChange={(phone) =>
                          setForm({ ...form, number: phone })
                        }
                      />
                      <DemoFormErrorTitle>
                        {formErrors.number}
                      </DemoFormErrorTitle>
                    </DemoFormInnerWrapper>
                    <DemoFormInnerWrapper>
                      <DemoFormInputLabel>Email</DemoFormInputLabel>
                      <DemoFormInput
                        onChange={inputHandler}
                        type="email"
                        name="email"
                        value={form.email}
                        error={formErrors.email}
                      />
                      <DemoFormErrorTitle>
                        {formErrors.email}
                      </DemoFormErrorTitle>
                    </DemoFormInnerWrapper>
                    <DemoFormInnerWrapper>
                      <DemoFormInputLabel>Website</DemoFormInputLabel>
                      <DemoFormInput
                        onChange={inputHandler}
                        type="text"
                        name="site"
                        error={formErrors.site}
                        value={form.site}
                      />
                      <DemoFormErrorTitle>{formErrors.site}</DemoFormErrorTitle>
                    </DemoFormInnerWrapper>
                  </DemoFormColumnWrapper>
                  <DemoFormInputLabel onChange={inputHandler}>
                    Tell us more about you project
                  </DemoFormInputLabel>
                  <DemoFormTextArea
                    onChange={inputHandler}
                    minRows={4}
                    name="textarea"
                    value={form.textarea}
                    type="email"
                    maxLength="1000"
                  ></DemoFormTextArea>
                </DemoFormInputWrapper>
                <DemoButtonWrapper>
                  {isTableScreen ? (
                    <SubmitFormBtn
                      onClick={() => setActive(false)}
                      bg="#F0F4F5"
                      color="#101820"
                      type="button"
                      hover="#101820"
                      hoverTxt="#fff"
                    >
                      Later
                    </SubmitFormBtn>
                  ) : (
                    ""
                  )}
                  {reRender ? null : (
                    <SubmitFormBtn
                      hover="#F0F4F5"
                      hoverTxt="#101820"
                      type="submit"
                    >
                      Submit
                    </SubmitFormBtn>
                    //      <SubmitFormBtn disabled={!disabled ? true : false} type="submit">
                    //   Submit
                    // </SubmitFormBtn> */}
                  )}
                </DemoButtonWrapper>
              </DemoForm>
            </>
          )}
        </DemoModalContentWrapper>
      </DemoModalContent>
    </DemoModalWrapperActive>
  );
};

export default Demo;
