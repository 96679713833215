import React from "react";
import { CheckBoxWrapper, CheckBox, CheckBoxLabel } from "../../../style";

const Switcher = ({ name, isToggle, onToggle }) => {
  return (
    <>
      <CheckBoxWrapper>
        <CheckBox
          id={name}
          type="checkbox"
          checked={isToggle}
          onChange={onToggle}
        />
        <CheckBoxLabel htmlFor={name} />
      </CheckBoxWrapper>
    </>
  );
};

export default Switcher;
