// import React, { useEffect, useState } from "react";
// import asquadSmallLogo from "../img/asquadFav.svg";
// import { HeaderStyled, DemoBtn, HeaderLinkTitle } from "../style";
// import Navbar from "./Navbar";
// import { useMediaQuery } from "react-responsive";
// import AsquadLogo from "../img/AsquadLogo";
// import Demo from "./Demo";
// const Header = () => {
//   const [position, setPosition] = useState(window.pageYOffset);
//   const [visible, setVisible] = useState(true);
//   const [active, setActive] = useState(false);
//   const isTableScreen = useMediaQuery({ query: "(min-width: 768px)" });
//   const isDesktopScreen = useMediaQuery({ query: "(min-width: 1366px)" });
//   let heightImg = 24;
//   const scrollToTop = () => {
//     setActive(true);

//     window.scrollTo({
//       top: 0,
//       behavior: "smooth",
//     });
//   };

//   if (isTableScreen) {
//     heightImg = 44;
//   }
//   useEffect(() => {
//     const handleScroll = () => {
//       let moving = window.pageYOffset;

//       setVisible(position > moving);
//       setPosition(moving);
//     };
//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   });

//   const cls = visible ? "visible" : "hidden";
//   return (
//     <>
//       {!isDesktopScreen && (
//         <HeaderStyled className={cls}>
//           <img style={{ height: heightImg }} src={asquadSmallLogo} alt="logo" />
//           <Navbar />
//         </HeaderStyled>
//       )}

//       {isDesktopScreen && (
//         <HeaderStyled className={cls}>
//           <AsquadLogo />
//           <div
//             style={{
//               width: "730px",
//               display: "flex",
//               justifyContent: "space-between",
//               alignItems: "center",
//             }}
//           >
//             <HeaderLinkTitle>Technologies</HeaderLinkTitle>
//             <HeaderLinkTitle>Solutions</HeaderLinkTitle>
//             <HeaderLinkTitle>About</HeaderLinkTitle>
//             <DemoBtn onClick={() => scrollToTop()} color="#fff">
//               Demo
//             </DemoBtn>
//           </div>
//         </HeaderStyled>
//       )}
//       <Demo firstIDLabel="id0" secondIdLabel="id1" active={active} setActive={setActive} />
//     </>
//   );
// };

// export default Header;
import React, { useEffect, useState } from "react";
import asquadSmallLogo from "../img/asquadFav.svg";
import {
  HeaderStyled,
  DemoBtn,
  HeaderLinkTitle,
  HidenHeaderMenu,
  HideMenuInnerWrapper,
  HideMenuWrapper,
  InnerMenuLink,
} from "../style";
import Navbar from "./Navbar";
import { useMediaQuery } from "react-responsive";
import AsquadLogo from "../img/AsquadLogo";
import Demo from "./Demo";

const Header = ({ value }) => {
  const [position, setPosition] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const [active, setActive] = useState(false);
  const isTableScreen = useMediaQuery({ query: "(min-width: 768px)" });
  const isDesktopScreen = useMediaQuery({ query: "(min-width: 1366px)" });
  let heightImg = 24;
  const scrollToTop = () => {
    setActive(true);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  if (isTableScreen) {
    heightImg = 44;
  }
  useEffect(() => {
    const handleScroll = () => {
      let moving = window.pageYOffset;

      setVisible(position > moving);
      setPosition(moving);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const cls = visible ? "visible" : "hidden";

  return (
    <>
      {!isDesktopScreen && (
        <HeaderStyled className={cls}>
          <p></p>
          {/* <img style={{ height: heightImg }} src={asquadSmallLogo} alt="logo" /> */}
          <Navbar />
        </HeaderStyled>
      )}

      {isDesktopScreen && value.routeScroll === "up" && (
        <HeaderStyled className={cls} transition={value.routeScroll === "up"}>
          <div style={{ zIndex: "1000" }}>
            <AsquadLogo />
          </div>
          <div
            style={{
              width: "730px",
              zIndex: "1000",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <HeaderLinkTitle>Technologies</HeaderLinkTitle>
            <HideMenuWrapper>
              <HeaderLinkTitle showMenu={true}>Solutions</HeaderLinkTitle>
              <HideMenuInnerWrapper>
                <InnerMenuLink>White Label</InnerMenuLink>
                <InnerMenuLink>Payment Service Provider</InnerMenuLink>
              </HideMenuInnerWrapper>
            </HideMenuWrapper>
            <HeaderLinkTitle>About</HeaderLinkTitle>
            <DemoBtn onClick={() => scrollToTop()} color="#fff">
              Demo
            </DemoBtn>
          </div>
          <HidenHeaderMenu></HidenHeaderMenu>
        </HeaderStyled>
      )}

      {isDesktopScreen && value.routeScroll === "down" && (
        <HeaderStyled
          className="hidden"
          transition={value.routeScroll === "down"}
        >
          <div style={{ zIndex: "1000" }}>
            <img
              style={{ height: heightImg }}
              src={asquadSmallLogo}
              alt="logo"
            />
          </div>

          <HidenHeaderMenu></HidenHeaderMenu>
        </HeaderStyled>
      )}
      <Demo
        firstIDLabel="id0"
        secondIdLabel="id1"
        active={active}
        setActive={setActive}
      />
    </>
  );
};

export default Header;
