/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { Context } from "../../Container";
import { CONFIG } from "../../../services/constants";
import { SectionContainer } from "../../../style";
import { AnimationWrapperForHide } from "../../../styleAnimation";
import { useMediaQuery } from "react-responsive";
import Header from "../../../components/Header";
import {
  TechnologiesTitleWrapper,
  SecondMainTitle,
  SubTitleGraySpan,
  TechnologiesSubTitleWrapper,
  TechnologiesSubTitle,
  TechnologiesDeskWrapper,
  TechnologiesIconWrapper,
} from "../../../style";
import java from "../../../img/java.svg";
import aws from "../../../img/aws.svg";
import awsGray from "../../../img/awsGrayIcon.svg";
import gdpr from "../../../img/gdpr.svg";
import gdprGray from "../../../img/gbprGrayIcon.svg";
import corezoid from "../../../img/corezoid.svg";
import pci from "../../../img/pci.svg";
import pciGray from "../../../img/pciGrayIcon.svg";

import amazonEks from "../../../img/amazonEks.svg";

import { Global2 } from "../SectionAwesome/style";

const SectionTechnologies = ({ index, value }) => {
  const isMobileScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const isTabletScreen = useMediaQuery({ query: "(min-width: 768px)" });
  // const isDesktopScreen = useMediaQuery({ query: "(min-width: 1366px)" });
  const isBigDesktopScreen = useMediaQuery({
    query: "(min-width: 1920px)",
  });
  let fs = "";
  let lh = "";
  if (isMobileScreen) {
    fs = 24;
    lh = 26;
  }

  if (isTabletScreen) {
    fs = 48;
  }
  if (isBigDesktopScreen) {
    fs = 64;
  }
  const context = useContext(Context);
  const {
    isLeaving,
    scrollPointer,
    isPossibleScrolling,
    setIsLeaving,
    setIsPossibleScrolling,
    direction,
  } = context;

  const [isReadyAnimationStart, setIsReadyAnimationStart] = useState(false);
  const [isLeaveReady, setIsLeaveReady] = useState(false);
  const [changeStyle, setChangeStyle] = useState();

  const start = CONFIG[index].first;
  const end = CONFIG[index].last || start;
  const isBetween = scrollPointer >= start && scrollPointer <= end;

  useEffect(() => {
    if (isLeaving === "ready" && isBetween) {
      setIsLeaveReady(true);
    } else {
      setTimeout(() => {
        setIsLeaveReady(false);
      }, 600);
    }
  }, [isLeaving, isBetween]);

  useEffect(() => {
    if (isBetween) {
      setTimeout(() => {
        setIsReadyAnimationStart(true);
      }, 1000);
    } else {
      setIsReadyAnimationStart(false);
    }
  }, [isBetween]);

  useEffect(() => {
    if (isBetween && isLeaveReady) {
      setTimeout(() => {
        setIsLeaving("finish");
      }, 600);
    }

    if (isBetween && !isPossibleScrolling) {
      setTimeout(() => {
        setIsPossibleScrolling(true);
      }, 1600);
    }
  }, [isLeaveReady]);

  const [animationNumber, setAnimationNumber] = useState(null);

  useEffect(() => {
    if (scrollPointer !== null && scrollPointer >= 0) {
      setTimeout(() => {
        setIsReadyAnimationStart(true);
      }, 0);
    }
    if (scrollPointer === 17) {
      setChangeStyle("");
    }
    if (scrollPointer === 18) {
      setChangeStyle("first");
    }
    if (scrollPointer === 19) {
      setChangeStyle("second");
    }
  }, [scrollPointer]);

  useEffect(() => {
    if (scrollPointer === 17 || scrollPointer === 18 || scrollPointer === 19) {
      setAnimationNumber("Section-2");
    }
    if (
      (scrollPointer === 20 && isLeaving === "finish" && direction !== 0) ||
      scrollPointer === 16
    ) {
      setAnimationNumber("leaveSection-2");
    }
  }, [scrollPointer, isLeaving, direction]);

  console.log(scrollPointer);
  return (
    <SectionContainer>
      {scrollPointer >= 1 && <Header value={value} />}

      <AnimationWrapperForHide
        isHide={isLeaveReady}
        isReadyAnimationStart={isReadyAnimationStart && isBetween}
      >
        <TechnologiesTitleWrapper>
          <SecondMainTitle fs={fs} mb="0" lh={lh}>
            Technologies
          </SecondMainTitle>
          <SubTitleGraySpan size={fs}>&</SubTitleGraySpan>
          <SecondMainTitle fs={fs} lh={lh}>
            Certificates
          </SecondMainTitle>
        </TechnologiesTitleWrapper>
        <TechnologiesSubTitleWrapper>
          <TechnologiesSubTitle
            changeStyle={changeStyle === "first"}
            color="#7B949D"
          >
            We use
          </TechnologiesSubTitle>
          <TechnologiesSubTitle
            changeStyle={changeStyle === "second"}
            color="#7B949D"
          >
            We comply with
          </TechnologiesSubTitle>
        </TechnologiesSubTitleWrapper>
        <Global2 isShow={isReadyAnimationStart} isAnimated={animationNumber}>
          <TechnologiesDeskWrapper>
            <TechnologiesIconWrapper changeStyle={changeStyle === "second"}>
              <img width="87" height="164" src={java} alt="img" />
              <img width="196" height="64" src={corezoid} alt="img" />
              <img width="196" height="98" src={amazonEks} alt="img" />
              {/* <GdprIcon /> */}
            </TechnologiesIconWrapper>
            <TechnologiesIconWrapper>
              <img
                width="122"
                height="73"
                src={changeStyle === "second" ? awsGray : aws}
                alt="img"
              />
              <img
                width="196"
                height="84"
                src={changeStyle === "first" ? pciGray : pci}
                alt="img"
              />
              <img
                width="98"
                height="98"
                src={changeStyle === "first" ? gdprGray : gdpr}
                alt="img"
              />
            </TechnologiesIconWrapper>
          </TechnologiesDeskWrapper>
        </Global2>
      </AnimationWrapperForHide>
    </SectionContainer>
  );
};

export default SectionTechnologies;
