/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { useMediaQuery } from "react-responsive";
import Demo from "../../../components/Demo";
import { Context } from "../../Container";
import Intro from "../../../components/Intro";
import { CONFIG } from "../../../services/constants";
import { ButtonContainer, ButtonDemo, AnimationImgWrapperMob } from "./style";
import { SectionContainer } from "../../../style";
import Header from "../../../components/Header";

const Hero = ({ index, value }) => {
  const context = useContext(Context);
  const {
    isLeaving,
    scrollPointer,
    isPossibleScrolling,
    setIsLeaving,
    setIsPossibleScrolling,
    setIsLoadedImages,
  } = context;

  const [isReadyAnimationStart, setIsReadyAnimationStart] = useState(false);
  const [isLeaveReady, setIsLeaveReady] = useState(false);
  const [active, setActive] = useState(false);

  let imgHeight = "";

  const isMobileScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const isTableScreen = useMediaQuery({ query: "(min-width: 768px)" });
  // // const isTableScreen = useMediaQuery({ query: "(max-width: 1330px)" });
  const isDesktopScreen = useMediaQuery({ query: "(min-width: 1131px)" });
  const scrollToTop = () => {
    setActive(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  if (isMobileScreen) {
    imgHeight = "778";
  }
  if (isTableScreen) {
    imgHeight = "1190";
  }
  // if (isDesktopScreen) {
  //   imgHeight = "768";
  // }

  const start = CONFIG[index].first;
  const end = CONFIG[index].last || start;
  const isBetween =
    scrollPointer !== null && scrollPointer >= start && scrollPointer <= end;
  useEffect(() => {
    if (isLeaving === "ready" && isBetween) {
      setIsLeaveReady(true);
    } else {
      setTimeout(() => {
        setIsLeaveReady(false);
      }, 600);
    }
  }, [isLeaving, isBetween]);

  useEffect(() => {
    if (isBetween) {
      setTimeout(() => {
        setIsReadyAnimationStart(true);
      }, 0);
    } else {
      setIsReadyAnimationStart(false);
    }
  }, [isBetween]);

  useEffect(() => {
    if (isLeaveReady && isBetween) {
      setTimeout(() => {
        setIsLeaving("finish");
      }, 600);
    }

    if (isBetween && !isPossibleScrolling) {
      setTimeout(() => {
        setIsPossibleScrolling(true);
      }, 3600);
    }
  }, [isLeaveReady]);

  return (
    <SectionContainer>
      {scrollPointer >= 1 && <Header value={value} />}
      <Intro
        isHide={isLeaveReady}
        isReadyAnimationStart={isReadyAnimationStart && isBetween}
      />
      <ButtonContainer>
        <ButtonDemo
          onClick={() => scrollToTop()}
          isHide={isLeaveReady}
          delay={2.2}
          isAnimated={isReadyAnimationStart && isBetween}
        >
          Demo
        </ButtonDemo>
      </ButtonContainer>

      {!isDesktopScreen && (
        <AnimationImgWrapperMob
          delay={2.8}
          isAnimated={isReadyAnimationStart && isBetween}
        >
          <img
            width="100%"
            height={imgHeight}
            src={require("../../../img/HeroImg.png")}
            alt="hero"
            loading="lazy"
            onLoad={() => setIsLoadedImages((prev) => prev + 1)}
          />
        </AnimationImgWrapperMob>
      )}

      <Demo
        firstIDLabel="id10"
        secondIdLabel="id11"
        active={active}
        setActive={setActive}
        isAnimated={isReadyAnimationStart && isBetween}
        // isHide={isLeave}
      />
    </SectionContainer>
  );
};

export default Hero;
