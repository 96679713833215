import styled, { css, keyframes } from "styled-components";

const isShownAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.8);
  }

  50% {
    opacity: 0.5;
    transform: translate(-50%, -50%) scale(1.3);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.3);
  }
`;

const isShown = css`
  animation: ${isShownAnimation} 2.6s ease 1.8s;
  animation-fill-mode: forwards;
`;

const Section0 = css`
  transition: top 1.2s ease, transform 1s;
`;

const SectionLeave0 = css`
  top: 0;
  transform: translate(-50%, -50%) scale(1) rotate(0deg);
  transition: top 1.2s ease, transform 1s;
`;

const Section1 = css`
  top: -100%;
  transform: translate(-50%, -50%) scale(0.5) rotate(90deg);
  transition: top 1.2s ease, left 1.2s ease, transform 1s;
`;

export const Global1 = styled.div`
  z-index: -1;
  position: fixed;
  top: 100%;
  left: 50%;
  right: -25%;
  transform: translate(-50%, -50%);
  z-index: 1;
  opacity: 0;
  transition: transform 1s;

  ${({ isShow }) => isShow && isShown}

  ${({ isAnimated }) => {
    if (isAnimated === "section-0") {
      return Section0;
    }

    if (isAnimated === "leave-0") {
      return SectionLeave0;
    }

    if (isAnimated === "section-1") {
      return Section1;
    }
  }}
`;
