import { useState, useEffect, useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { Context } from "../../Container";
import { Global2 } from "./style";
import src from "../../../img/global5.png";

const GlobalBlock6 = () => {
  const isMobileScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const isTableScreen = useMediaQuery({ query: "(min-width: 768px)" });
  const isDescScreen = useMediaQuery({ query: "(min-width: 768px)" });
  const isBigDesktopScreen = useMediaQuery({ query: "(min-width: 1920px)" });

  let widthImg = "";
  let heightImg = "";

  if (isMobileScreen) {
    widthImg = "280px";
    heightImg = "280px";
  }

  if (isTableScreen) {
    widthImg = "526px";
    heightImg = "526px";
  }

  if (isDescScreen) {
    widthImg = "628px";
    heightImg = "628px";
  }
  if (isBigDesktopScreen) {
    widthImg = "648px";
    heightImg = "648px";
  }

  const context = useContext(Context);
  const { isLeaving, scrollPointer, direction, setIsLoadedImages } = context;

  const [isReadyAnimationStart, setIsReadyAnimationStart] = useState(false);
  const [animationNumber, setAnimationNumber] = useState(null);

  useEffect(() => {
    if (scrollPointer !== null && scrollPointer === 5) {
      setTimeout(() => {
        setIsReadyAnimationStart(true);
      }, 0);
    }
  }, [scrollPointer]);

  useEffect(() => {
    if (scrollPointer === 6) {
      setAnimationNumber("Section-2");
    }
    if (
      (scrollPointer === 6 && isLeaving === "finish" && direction !== 0) ||
      scrollPointer !== 6
    ) {
      setAnimationNumber("leaveSection-2");
    }
  }, [scrollPointer, isLeaving, direction]);

  return (
    <Global2 isShow={isReadyAnimationStart} isAnimated={animationNumber}>
      {/* {isDesktopScreen && ( */}
      <img
        loading="lazy"
        // width="100%"
        src={src}
        style={{
          width: widthImg,
          height: heightImg,
          // inset: positionImg,
          margin: "auto",
          zIndex: "-1",
        }}
        alt="quotes"
        onLoad={() => setIsLoadedImages((prev) => prev + 1)}
      />
      {/* )} */}
    </Global2>
  );
};

export default GlobalBlock6;
