/* eslint-disable no-dupe-keys */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  useState,
  useEffect,
  useRef,
  useCallback,
  createContext,
  useMemo,
} from "react";
import { ContainerWrapper } from "./style";
import SectionHero from "../Sections/SectionHero";
import SectionIntegrations from "../Sections/SectionIntegrations";
import SectionQuotes from "../Sections/SectionQuotes";
import SectionAdvantages from "../Sections/SectionAdvantages";
import SectionInternals from "../Sections/SectionInternals";
import GlobalBlock1 from "../Globals/GlobalHero";
import GlobalBlock2 from "../Globals/GlobalQuotes";
import GlobalBlock3 from "../Globals/GlobalAdvantages";
import { CONFIG } from "../../services/constants";
import Preloader from "../../components/Preloader";
import GlobalBlock5 from "../Globals/GlobalsInternals";
import GlobalBlock6 from "../Globals/GlobalsInternals/index1";
import GlobalBlock7 from "../Globals/GlobalsInternals/index2";
import SectionInternals1 from "../Sections/SectionInternals/index1";
import SectionInternals2 from "../Sections/SectionInternals/index2";
import SectionAwesome from "../Sections/SectionAwesome";
import SectionAdminFist from "../Sections/SectionAdminPanel";
import GlobalBlock8 from "../Globals/GlobalAdmin";
import SectionAdminSecond from "../Sections/SectionAdminPanel/index1";
import GlobalBlock9 from "../Globals/GlobalAdmin/index1";
import GlobalBlock10 from "../Globals/GlobalAdmin/index3";
import SectionAdminThree from "../Sections/SectionAdminPanel/index2";
import SectionHoldOn from "../Sections/SectionHoldOn";
import SectionNumbers from "../Sections/SectionNumbers";
import SectionTechnologies from "../Sections/SectionsTechnologies";
// import GlobalBlock12 from "../Globals/GlobalTechnologies/Index";

import GlobalBlock11 from "../Globals/GlobalNumbers";

const MAX_SCROLL =
  CONFIG[Object.keys(CONFIG).length - 1].last ||
  CONFIG[Object.keys(CONFIG).length - 1].first;

export const Context = createContext({
  direction: 0,
  isLeaving: "stop",
  scrollPointer: null,
  direction: null,
  isPossibleScrolling: false,
  isLoadedImages: 0,
  setIsLeaving: () => {},
  setScrollPointer: () => {},
  setIsPossibleScrolling: () => {},
  setIsLoadedImages: () => {},
});

const Container = () => {
  const [isLeaving, setIsLeaving] = useState("stop");
  const [isPossibleScrolling, setIsPossibleScrolling] = useState(false);
  const [currentSection, setCurrentSection] = useState(0);
  const [scrollPointer, setScrollPointer] = useState(null);
  const [isLoadedImages, setIsLoadedImages] = useState(0);
  const [direction, setDirection] = useState(0);
  const [routeScroll, setRouteScroll] = useState("");
  const [isWheel, setIsWheel] = useState([]);

  const value = useMemo(
    () => ({
      direction,
      isLeaving,
      routeScroll,
      scrollPointer,
      isPossibleScrolling,
      isLoadedImages,
      setIsLeaving,
      setScrollPointer,
      setIsPossibleScrolling,
      setIsLoadedImages,
    }),
    [direction, isLeaving, scrollPointer, isPossibleScrolling, isLoadedImages]
  );

  const ref = useRef();

  const isMaxScroll =
    (direction === -1 && scrollPointer > 0) ||
    (direction === 1 && scrollPointer < MAX_SCROLL);

  const onWheel = useCallback(
    (event) => {
      if (isWheel.length === 0 && isPossibleScrolling) {
        setIsWheel([event]);
      }
    },
    [isWheel, isPossibleScrolling]
  );

  const clearAfterScroll = useCallback(() => {
    setIsWheel([]);
    setDirection(0);
    setIsLeaving("stop");
  }, []);

  useEffect(() => {
    ref.current.addEventListener("wheel", onWheel, false);

    return () => {
      ref.current.removeEventListener("wheel", onWheel, false);
    };
  }, [onWheel, ref]);

  useEffect(() => {
    if (isWheel.length === 1) {
      setDirection(isWheel[0].wheelDeltaY < 0 ? 1 : -1);
    }
  }, [isWheel]);

  useEffect(() => {
    if (
      direction !== 0 &&
      isPossibleScrolling &&
      isLeaving === "stop" &&
      isMaxScroll
    ) {
      setIsLeaving("ready");
      setIsPossibleScrolling(false);
    }
  }, [direction, isLeaving]);

  useEffect(() => {
    if (isLeaving === "finish") {
      setScrollPointer((prev) => prev + direction);
    }
  }, [isLeaving]);

  useEffect(() => {
    clearAfterScroll();

    Object.values(CONFIG).forEach((item, index) => {
      const start = item.first;
      const end = item.last || start;

      if (scrollPointer >= start && scrollPointer <= end) {
        if (index !== currentSection) {
          setCurrentSection(index);
        }
      }
    });
  }, [scrollPointer]);

  document.addEventListener("wheel", (e) => {
    if (e.wheelDeltaY < 0) {
      setRouteScroll("down");
    } else {
      setRouteScroll("up");
    }
  });

  useEffect(() => {
    const clientHeight = ref.current.clientHeight;

    const destinationSection = clientHeight * currentSection;

    Promise.resolve().then(() =>
      ref.current.scrollTo({ top: destinationSection, behavior: "smooth" })
    );
  }, [currentSection]);

  return (
    <ContainerWrapper ref={ref}>
      <Context.Provider value={value}>
        <SectionHero value={value} index={0} />
        <SectionIntegrations value={value} index={1} />
        <SectionQuotes value={value} index={2} />
        <SectionAdvantages value={value} index={3} />
        <SectionInternals value={value} index={4} />
        <SectionInternals1 value={value} index={5} />
        <SectionInternals2 value={value} index={6} />
        <SectionAwesome value={value} index={7} />
        <SectionAdminFist value={value} index={8} />
        <SectionAdminSecond value={value} index={9} />
        <SectionAdminThree value={value} index={10} />
        <SectionNumbers value={value} index={11} />
        <SectionHoldOn value={value} index={12} />
        <SectionTechnologies value={value} index={13} />

        <div>
          <GlobalBlock1 />
          <GlobalBlock2 />
          <GlobalBlock3 />
          <GlobalBlock5 />
          <GlobalBlock6 />
          <GlobalBlock7 />
          <GlobalBlock8 />
          <GlobalBlock9 />
          <GlobalBlock10 />
          <GlobalBlock11 />
          {/* <GlobalBlock12 /> */}
          <Preloader />
        </div>
      </Context.Provider>
    </ContainerWrapper>
  );
};

export default Container;
