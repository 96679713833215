import styled, { css } from "styled-components";

export const AdvantageTag = styled.span`
  color: #eb0075;
  transition: color 0.5s;
  font-size: 20px;

  @media (min-width: 1366px) {
    font-size: 42px;
    margin-right: 0;
  }

  ${({ isAnimated }) =>
    isAnimated &&
    css`
      color: #0fca87;
    `}
`;

export const AdvantageTempTag = styled.span`
  opacity: 1;
  transition: opacity 0.5s;
  font-size: 20px;

  ${({ isHide }) =>
    isHide &&
    css`
      opacity: 0 !important;
    `}
  @media (min-width: 1366px) {
    font-size: 42px;
    margin-right: 0;
  }
`;

export const AdvantageTransformTitle = styled.span`
  transform: translateX(0);
  transition: transform 0.5s;

  ${({ isAnimated }) =>
    isAnimated &&
    css`
      transform: translateX(-70px) !important;
    `}
`;

export const AdvantageModalWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  border-radius: 20px;
  width: 1280px;
`;

export const AdvantageModalList = styled.ul`
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  padding: 64px 0px 0px 24px;
`;

export const AdvantageModalListSecond = styled.ul`
  padding: 64px 0px 0px 24px;
`;
