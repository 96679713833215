import { useState, useEffect, useContext } from "react";
// import { useMediaQuery } from "react-responsive";
import { Context } from "../../Container";
import { Global3 } from "./style";
import { HeadSubTitle, HeadTitle } from "../../../style";

const GlobalBlock11 = () => {
  // const isMobileScreen = useMediaQuery({ query: "(max-width: 767px)" });
  // const isTableScreen = useMediaQuery({ query: "(min-width: 768px)" });
  // const isDescScreen = useMediaQuery({ query: "(min-width: 768px)" });
  // const isBigDesktopScreen = useMediaQuery({ query: "(min-width: 1920px)" });

  const context = useContext(Context);
  const { isLeaving, scrollPointer, direction, setIsLoadedImages } = context;

  const [isReadyAnimationStart, setIsReadyAnimationStart] = useState(false);
  const [animationNumber, setAnimationNumber] = useState(null);

  useEffect(() => {
    if (scrollPointer !== null && scrollPointer >= 0) {
      setTimeout(() => {
        setIsReadyAnimationStart(true);
      }, 0);
    }
  }, [scrollPointer]);

  useEffect(() => {
    if (scrollPointer === 13 || scrollPointer === 14 || scrollPointer === 15) {
      setAnimationNumber("section-3");
    }

    if (
      (scrollPointer === 16 && isLeaving === "finish" && direction === 1) ||
      scrollPointer === 12
    ) {
      setAnimationNumber("leaveSection-3");
    }
  }, [scrollPointer, isLeaving, direction]);

  return (
    <Global3 isShow={isReadyAnimationStart} isAnimated={animationNumber}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <HeadSubTitle>Numbers Don’t Lie. </HeadSubTitle>
        <HeadTitle fs={46}>&nbsp; Neither Do We</HeadTitle>
      </div>
      <div
        onLoad={() => setIsLoadedImages((prev) => prev + 1)}
        style={{
          background: "black",
          height: "650px",
          zIndex: "-1",
          boxShadow: "0px 0px 25px rgba(123, 148, 157, 0.5)",
          borderRadius: "15px",
        }}
      ></div>
    </Global3>
  );
};

export default GlobalBlock11;
