/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { Context } from "../../views/Container";
import Spinner from "../../img/animatePreloader.svg";
import {
  PreloaderContainer,
  PreloaderWrapper,
  PreloaderSpinner,
  PreloaderText,
  PreloaderTextCounter,
  PreloaderTextSub,
} from "./style";

const TIME_LIMIT = 3000;
const PERCENT_COUNT = 99;
const DEFAULT_SETTIMEOUT_TICK = 4;
const TOTAL_IMAGES_COUNT = 12;

const Preloader = () => {
  const [percent, setPercent] = useState(0);
  const [isAnimated, setIsAnimated] = useState(false);
  const [isHide, setIsHide] = useState(false);

  const context = useContext(Context);
  const {
    isLoadedImages,
    scrollPointer,
    setScrollPointer,
    setIsPossibleScrolling,
  } = context;

  useEffect(() => {
    progress();
  }, [percent]);

  useEffect(() => {
    if (isLoadedImages === TOTAL_IMAGES_COUNT && percent === 99) {
      setIsAnimated(true);
    }
  }, [isLoadedImages, percent]);

  useEffect(() => {
    if (isAnimated) {
      setTimeout(() => {
        setScrollPointer(0);
      }, 500);
      setTimeout(() => {
        setIsPossibleScrolling(true);
      }, 3600);
    }
  }, [isAnimated]);

  useEffect(() => {
    if (scrollPointer !== null) {
      setTimeout(() => {
        setIsHide(true);
      }, 1000);
    }
  }, [scrollPointer]);

  const progress = () => {
    if (percent < PERCENT_COUNT) {
      setTimeout(() => {
        setPercent((prev) => prev + 1);
      }, TIME_LIMIT / PERCENT_COUNT - DEFAULT_SETTIMEOUT_TICK);
    }
  };

  return (
    <PreloaderContainer isHide={isHide}>
      <PreloaderWrapper>
        <PreloaderSpinner>
          <img src={Spinner} alt="spinner" />
        </PreloaderSpinner>
        <PreloaderText isAnimated={isAnimated}>
          <PreloaderTextCounter>{percent}</PreloaderTextCounter>
          <PreloaderTextSub>%</PreloaderTextSub>
        </PreloaderText>
      </PreloaderWrapper>
    </PreloaderContainer>
  );
};

export default Preloader;
