import styled, { css } from "styled-components";

const Section2 = css`
  opacity: 1;
  /* transition: opacity 1.2s linear 0.6s; */
  transition: 1s;
`;

const LeaveSection2 = css`
  z-index: -1;
  position: fixed;
  top: 59%;
  left: 101%;
  transform: translate(-50%, -50%);
  z-index: 1;
  opacity: 1;
  transition: 1s linear;
  /* transition: opacity 0.5s; */
`;
const LeaveSection = css`
  opacity: 0;
  position: fixed;
  top: 59%;
  left: 101%;
  transition: opacity 1s linear;
`;

export const Section3 = css`
  opacity: 1;
  transition: opacity 1.2s linear 0.6s;
`;

export const LeaveSection3 = css`
  opacity: 0;
  transition: opacity 1s linear;
`;

export const Global2 = styled.div`
  z-index: -1;
  position: fixed;
  top: 59%;
  left: 70%;
  transform: translate(-50%, -50%);
  z-index: 1;
  opacity: 0;
  transition: opacity 0.5s;

  ${({ isAnimated, isShow }) => {
    if (isAnimated === "Section3" && isShow) {
      return Section2;
    }
    if (isAnimated === "LeaveSection3" && isShow) {
      return LeaveSection2;
    }
  }}
`;

export const Global12 = styled.div`
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  background-color: #101820;
  width: 93vh;
  height: 100vh;
  z-index: -1;
  position: fixed;
  top: 50%;
  left: 75%;
  transform: translate(-50%, -50%);
  z-index: 1;
  opacity: 0;
  transition: opacity 0.5s;

  ${({ isAnimated, isShow }) => {
    if (isAnimated === "Section3" && isShow) {
      return Section2;
    }
    if (isAnimated === "LeaveSection3" && isShow) {
      return LeaveSection2;
    }
  }}
`;

export const Global8 = styled.div`
  z-index: -1;
  position: fixed;
  top: 59%;
  left: 78%;
  transform: translate(-50%, -50%);
  z-index: 1;
  opacity: 0;
  transition: opacity 1s linear;

  ${({ isAnimated, isShow }) => {
    if (isAnimated === "Section-2" && isShow) {
      return Section2;
    }
    if (isAnimated === "leaveSection-2" && isShow) {
      return LeaveSection2;
    }
    if (isAnimated === "leaveSection" && isShow) {
      return LeaveSection;
    }
  }}
`;
