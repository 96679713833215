import styled from "styled-components";

export const ContainerWrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: column nowrap;
  font-family: var(--mainFont);
  overflow: hidden;
  outline: 1px dashed lightgray;
  width: 100vw;
  height: 100vh;
  flex-flow: column nowrap;
  scroll-snap-type: y mandatory;
  flex: none;
  background-color: #e5e5e5;

  & > div {
    scroll-snap-stop: always;
    width: 100vw;
    height: 100vh;
    position: relative;
    text-align: center;
    flex: none;
    background: transparent;
    z-index: 1;

    &:last-child {
      z-index: 0;
    }
  }
`;
