const AsquadLogo = () => (
  <svg
    width="166"
    height="32"
    viewBox="0 0 166 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6058 27.2545C10.3091 27.2545 8.06397 26.5735 6.15427 25.2977C4.24456 24.0218 2.75602 22.2084 1.87683 20.0867C0.99764 17.965 0.767282 15.6302 1.21488 13.3776C1.66248 11.1249 2.76793 9.05556 4.39148 7.43113C6.01503 5.80669 8.08378 4.7001 10.3362 4.25127C12.5886 3.80245 14.9235 4.03153 17.0457 4.90956C19.1679 5.78759 20.9821 7.27514 22.259 9.18414C23.5359 11.0931 24.2181 13.3379 24.2193 15.6346V27.2481L12.6058 27.2545Z"
      fill="#101820"
    />
    <path
      d="M120.782 27.2545C118.485 27.2545 116.24 26.5735 114.33 25.2977C112.42 24.0218 110.932 22.2084 110.053 20.0867C109.173 17.965 108.943 15.6302 109.391 13.3776C109.838 11.1249 110.944 9.05556 112.567 7.43113C114.191 5.80669 116.26 4.7001 118.512 4.25127C120.764 3.80245 123.099 4.03153 125.221 4.90956C127.344 5.78759 129.158 7.27514 130.435 9.18414C131.712 11.0931 132.394 13.3379 132.395 15.6346V27.2481L120.782 27.2545Z"
      fill="#101820"
    />
    <path
      d="M40.231 21.1861C40.2329 22.4119 39.8712 23.6106 39.1915 24.6308C38.5119 25.6509 37.5449 26.4465 36.413 26.9168C35.2811 27.3872 34.035 27.5113 32.8326 27.2733C31.6301 27.0353 30.5253 26.446 29.6579 25.5799C28.7904 24.7138 28.1994 23.6099 27.9596 22.4078C27.7198 21.2057 27.8419 19.9595 28.3105 18.8269C28.7792 17.6942 29.5733 16.726 30.5924 16.0448C31.6114 15.3636 32.8097 15 34.0354 15H40.231V21.1861Z"
      fill="#101820"
    />
    <path
      d="M40.2148 8.82377C40.2142 7.59826 40.577 6.40009 41.2574 5.38081C41.9378 4.36154 42.9053 3.56694 44.0373 3.09753C45.1693 2.62811 46.4152 2.50496 47.6172 2.74366C48.8192 2.98236 49.9235 3.57219 50.7903 4.43853C51.6571 5.30487 52.2475 6.40882 52.4868 7.61073C52.7261 8.81264 52.6036 10.0585 52.1347 11.1908C51.6659 12.3231 50.8718 13.2909 49.8529 13.9718C48.8339 14.6527 47.636 15.0162 46.4104 15.0162H40.2148V8.82377Z"
      fill="#101820"
    />
    <path
      d="M65.482 27.2266C63.1882 27.2254 60.9463 26.544 59.0397 25.2685C57.1332 23.9931 55.6477 22.181 54.771 20.0613C53.8944 17.9416 53.666 15.6096 54.1147 13.3601C54.5634 11.1106 55.6691 9.04473 57.292 7.42365C58.9149 5.80257 60.982 4.69913 63.232 4.25287C65.4819 3.8066 67.8137 4.03754 69.9324 4.91651C72.0512 5.79547 73.8617 7.28297 75.135 9.1909C76.4083 11.0988 77.0873 13.3415 77.086 15.6353V27.2203L65.482 27.2266Z"
      fill="#101820"
    />
    <path
      d="M148.727 4.79883C146.43 4.79883 144.184 5.48004 142.274 6.75629C140.364 8.03255 138.876 9.84652 137.997 11.9688C137.118 14.0911 136.888 16.4264 137.337 18.6793C137.785 20.9322 138.891 23.0015 140.516 24.6255C142.14 26.2496 144.21 27.3554 146.463 27.8032C148.716 28.2509 151.051 28.0205 153.173 27.141C155.295 26.2615 157.109 24.7725 158.385 22.8622C159.66 20.952 160.341 18.7063 160.34 16.4092V4.79883H148.727Z"
      fill="#101820"
    />
    <path
      d="M81.332 5.63086V15.6352C81.332 18.7153 82.5556 21.6693 84.7336 23.8472C86.9115 26.0252 89.8655 27.2488 92.9456 27.2488C96.0257 27.2488 98.9797 26.0252 101.158 23.8472C103.336 21.6693 104.559 18.7153 104.559 15.6352V5.63086H81.332Z"
      fill="#101820"
    />
    <path
      d="M79.4659 31.996C78.8295 31.996 78.2192 31.7431 77.7692 31.2931C77.3192 30.8432 77.0664 30.2328 77.0664 29.5964L77.0886 27.2255H79.4659C79.782 27.2169 80.0965 27.2722 80.3907 27.3879C80.6849 27.5037 80.9527 27.6776 81.1782 27.8992C81.4037 28.1208 81.5821 28.3856 81.7029 28.6778C81.8238 28.9699 81.8844 29.2835 81.8813 29.5996C81.8817 29.9148 81.82 30.227 81.6995 30.5184C81.5791 30.8097 81.4023 31.0744 81.1795 31.2973C80.9566 31.5202 80.6919 31.6969 80.4006 31.8173C80.1092 31.9378 79.797 31.9995 79.4818 31.9991L79.4659 31.996Z"
      fill="#101820"
    />
    <path
      d="M165.139 2.39952C165.137 3.0354 164.884 3.64475 164.434 4.09439C163.985 4.54402 163.375 4.79736 162.739 4.79904H160.34V2.39952C160.339 2.0843 160.401 1.77208 160.522 1.48077C160.642 1.18946 160.819 0.924773 161.042 0.701873C161.265 0.478974 161.529 0.302242 161.821 0.181802C162.112 0.0613629 162.424 -0.000415692 162.739 2.10501e-06C163.376 2.10501e-06 163.986 0.252805 164.436 0.702803C164.886 1.1528 165.139 1.76313 165.139 2.39952Z"
      fill="#101820"
    />
  </svg>
);
export default AsquadLogo;
