/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext, useRef } from "react";
import { Context } from "../../Container";
import { CONFIG } from "../../../services/constants";
import { SectionContainer } from "../../../style";
import {
  SectionQuotesContainer,
  QuotesTextWrapper,
  QuotesText,
  QuotesTextAuthor,
} from "./style";
import { Container } from "../../../style";
// import { useMediaQuery } from "react-responsive";
import Header from "../../../components/Header";

const SectionQuotes = ({ index, value }) => {
  const context = useContext(Context);
  const {
    isLeaving,
    scrollPointer,
    isPossibleScrolling,
    setIsLeaving,
    setIsPossibleScrolling,
  } = context;
  // const isMobileScreen = useMediaQuery({ query: "(max-width: 767px)" });
  // const isTableScreen = useMediaQuery({ query: "(min-width: 768px)" });
  // const isDesktopScreen = useMediaQuery({ query: "(min-width: 1366px)" });

  let firstBlock = 190;
  let secondBlock = 164;

  const refQuotesTextFirst = useRef();
  const refQuotesTextSecond = useRef();

  const [isReadyAnimationStart, setIsReadyAnimationStart] = useState(false);
  const [isLeaveReady, setIsLeaveReady] = useState(false);
  const [currentSizes, setCurrentSizes] = useState({
    first: {
      height: 0,
      width: 0,
    },
    second: {
      height: 0,
      width: 0,
    },
  });

  const start = CONFIG[index].first;
  const end = CONFIG[index].last || start;
  const isBetween = scrollPointer >= start && scrollPointer <= end;

  useEffect(() => {
    if (refQuotesTextFirst && refQuotesTextSecond) {
      setCurrentSizes({
        first: {
          height: refQuotesTextFirst.current.clientHeight,
          width: refQuotesTextFirst.current.clientWidth,
        },
        second: {
          height: refQuotesTextSecond.current.clientHeight,
          width: refQuotesTextSecond.current.clientWidth,
        },
      });
    }
  }, [refQuotesTextFirst, refQuotesTextSecond]);

  useEffect(() => {
    if (isLeaving === "ready" && isBetween) {
      setIsLeaveReady(true);
    } else {
      setTimeout(() => {
        setIsLeaveReady(false);
      }, 600);
    }
  }, [isLeaving, isBetween]);

  useEffect(() => {
    if (isBetween) {
      setTimeout(() => {
        setIsReadyAnimationStart(true);
      }, 1000);
    } else {
      setIsReadyAnimationStart(false);
    }
  }, [isBetween]);

  useEffect(() => {
    if (isBetween && isLeaveReady) {
      setTimeout(() => {
        setIsLeaving("finish");
      }, 1000);
    }

    if (isBetween && !isPossibleScrolling) {
      setTimeout(() => {
        setIsPossibleScrolling(true);
      }, 1600);
    }
  }, [isLeaveReady]);

  return (
    <SectionContainer>
      {scrollPointer >= 1 && <Header value={value} />}
      <Container>
        <SectionQuotesContainer>
          <QuotesTextWrapper
            isHide={isLeaveReady}
            position="top"
            isAnimated={isReadyAnimationStart && isBetween}
            height={firstBlock}
            newHeight={currentSizes.first.height}
            newWidth={currentSizes.first.width}
          >
            <QuotesText ref={refQuotesTextFirst}>
              <p>
                We tamed the «integrator's zoo», so{" "}
                <b>you don't have to handle multiple</b> integrations. Instead,
                use our white-label PSP solution to create a great payment
                experience without investing your own money and time.
              </p>
            </QuotesText>
          </QuotesTextWrapper>
          <QuotesTextWrapper
            isHide={isLeaveReady}
            position="bottom"
            height={secondBlock}
            isAnimated={isReadyAnimationStart}
            delay={3.3}
            newHeight={currentSizes.second.height}
            newWidth={currentSizes.second.width}
          >
            <QuotesText ref={refQuotesTextSecond}>
              <p>
                <em>
                  «You just take a branch, copy, change a couple of blocks, make
                  sure everything works, switch and back to your work.»
                </em>
              </p>
              <QuotesTextAuthor>© asquad CTO</QuotesTextAuthor>
            </QuotesText>
          </QuotesTextWrapper>
        </SectionQuotesContainer>
      </Container>
    </SectionContainer>
  );
};

export default SectionQuotes;
