import React from "react";

import {
  IntegrationsTitle,
  // Container,
  IntegrationsWrapper,
  SubTitleGray,
  ItalicTitle,
  TabletWrapper,
} from "../style";
import {
  AnimationWrapperForHide,
  TransformText,
  TransformTextWrapper,
  TransformShowText,
} from "../styleAnimation";

import { SectionIntegrationMainTextWrapper } from "../views/Sections/SectionIntegrations/style";

import { useMediaQuery } from "react-responsive";

const TEXT_HEIGHT = 78;

const Integrations = ({ isHide, isReadyAnimationStart }) => {
  const isMobileScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const isTableScreen = useMediaQuery({ query: "(min-width: 768px)" });
  const isDescScreen = useMediaQuery({ query: "(min-width: 1366x)" });
  const isBigDesktopScreen = useMediaQuery({ query: "(min-width: 1920px)" });
  let subTitleFs = "";
  let mainSubTitle = "";
  let lhTxt = "";
  let blockHeight = "s";
  if (isMobileScreen) {
    subTitleFs = "14";
    mainSubTitle = "18";
    blockHeight = "115";
  }
  if (isTableScreen) {
    subTitleFs = "24";
    mainSubTitle = "32";
  }
  if (isDescScreen) {
    subTitleFs = "24";
    mainSubTitle = "32";
  }
  if (isBigDesktopScreen) {
    subTitleFs = "24";
    mainSubTitle = "36";
    lhTxt = "42";
  }
  return (
    <AnimationWrapperForHide isHide={isHide} isAnimated={isReadyAnimationStart}>
      {/* <Container> */}
      <TransformTextWrapper jc={"center"}>
        <TransformText
          isAnimated={isReadyAnimationStart}
          delay={0}
          height={TEXT_HEIGHT}
        >
          <IntegrationsTitle>Key</IntegrationsTitle>
        </TransformText>
        <TransformText
          isAnimated={isReadyAnimationStart}
          delay={0.1}
          height={TEXT_HEIGHT}
        >
          <IntegrationsTitle>Integrations</IntegrationsTitle>
        </TransformText>
        <SectionIntegrationMainTextWrapper>
          <TransformText
            isAnimated={isReadyAnimationStart}
            delay={0.2}
            height={TEXT_HEIGHT}
          >
            <IntegrationsTitle>In</IntegrationsTitle>
          </TransformText>
          <TransformText
            isAnimated={isReadyAnimationStart}
            delay={0.3}
            height={TEXT_HEIGHT}
          >
            <IntegrationsTitle>One</IntegrationsTitle>
          </TransformText>
          <TransformText
            isAnimated={isReadyAnimationStart}
            delay={0.4}
            height={TEXT_HEIGHT}
          >
            <IntegrationsTitle>Day</IntegrationsTitle>
          </TransformText>
        </SectionIntegrationMainTextWrapper>
      </TransformTextWrapper>
      <TransformShowText
        height={blockHeight}
        isAnimated={isReadyAnimationStart}
        column
      >
        <IntegrationsWrapper>
          <SubTitleGray size={subTitleFs}>
            Recent integration records:
          </SubTitleGray>
          <TabletWrapper>
            <SubTitleGray lh={lhTxt} size={mainSubTitle}>
              New bank{" "}
              <ItalicTitle color={"#0062eb"} data-text="12 hours">
                12 hours
              </ItalicTitle>
            </SubTitleGray>
            <SubTitleGray lh={lhTxt} size={mainSubTitle}>
              An integration{" "}
              <ItalicTitle data-text="edit 30 min" color={"#0062eb"}>
                edit 30 min
              </ItalicTitle>
            </SubTitleGray>
          </TabletWrapper>
        </IntegrationsWrapper>
      </TransformShowText>
      {/* </Container> */}
    </AnimationWrapperForHide>
  );
};

export default Integrations;
