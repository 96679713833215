import styled, { css } from "styled-components";

const Section2 = css`
  opacity: 1;
  transition: opacity 2.6s linear 1.4s;
`;

const LeaveSection2 = css`
  opacity: 0;
  transition: opacity 1s linear;
`;

export const Global2 = styled.div`
  z-index: -1;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  opacity: 0;
  transition: opacity 1s;

  ${({ isAnimated, isShow }) => {
    if (isAnimated === "Section-2" && isShow) {
      return Section2;
    }
    if (isAnimated === "leaveSection-2" && isShow) {
      return LeaveSection2;
    }
  }}
`;
