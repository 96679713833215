import React, { useState } from "react";

import "react-sliding-pane/dist/react-sliding-pane.css";
import burgerIcon from "../img/burger.svg";
import closeBtn from "../img/closeBtn.svg";
import {
  SlideNavBarMenu,
  NavBarLinkWrapper,
  NavBarLink,
  NavBarLinkInnerWrapper,
  NavBarLinkInner,
  DemoBtn,
} from "../style";
import Demo from "./Demo";
import { useMediaQuery } from "react-responsive";
const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);
  // const isMobileScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const isTableScreen = useMediaQuery({ query: "(min-width: 768px)" });

  let heightImg = 25;

  if (isTableScreen) {
    heightImg = 44;
  }
  const scrollToTop = () => {
    setActive(true);
    setOpen(!open);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      {open ? (
        <img
          style={{ width: heightImg, height: heightImg }}
          src={closeBtn}
          onClick={() => setOpen(!open)}
          alt="menu"
        />
      ) : (
        <img
          style={{ width: heightImg, height: heightImg }}
          src={burgerIcon}
          onClick={() => setOpen(!open)}
          alt="menu"
        />
      )}

      <SlideNavBarMenu hideHeader isOpen={open} onRequestClose={() => setOpen(!open)}>
        <NavBarLinkWrapper>
          <NavBarLink>Technology</NavBarLink>
          <NavBarLink>Solutions</NavBarLink>
          <NavBarLinkInnerWrapper>
            <NavBarLinkInner to="/">White Label</NavBarLinkInner>
            <NavBarLinkInner to="/">Payment Service Provider</NavBarLinkInner>
          </NavBarLinkInnerWrapper>
          <NavBarLink>About</NavBarLink>
        </NavBarLinkWrapper>
        <DemoBtn onClick={() => scrollToTop()} color="#fff">
          Demo
        </DemoBtn>
      </SlideNavBarMenu>
      <Demo firstIDLabel="id2" secondIdLabel="id3" active={active} setActive={setActive} />
    </div>
  );
};

export default Navbar;
