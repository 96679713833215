import styled, { css, keyframes } from "styled-components";

const isShowToUp = keyframes`
  0% {
    transform: translateY(200%);
    opacity: 0
  }

  50% {
    transform: translateY(100%);
    opacity: 0.2
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const isShowToUpCss = css`
  animation: ${isShowToUp} 0.8s linear 0.8s;
  animation-fill-mode: forwards;
`;

export const TransformShowBlock = styled.div`
  /* height: ${(props) => (props.height ? props.height : "56")}px; */
  display: flex;
  justify-content: center;
  margin-top: 314px;
  align-items: flex-end;
  flex-direction: column;
  transform: translateY(200%);
  opacity: 0;

  animation-play-state: ${({ isAnimated }) =>
    isAnimated ? "running" : "paused"};

  ${({ isAnimated }) => isAnimated && isShowToUpCss}
`;

export const TransformLeftToRight = styled.div`
  position: relative;
  background-color: inherit;
  padding: 15px;
  border-radius: 10px;
  margin-right: 16px;
  margin-bottom: 24px;
  color: #fff;
  height: 55px;
  transition: 1s linear, opacity 0.7s linear 1s;
  opacity: 0;

  &:nth-child(1) {
    margin-right: 24px;
  }
  &:nth-child(3) {
    margin-right: 27px;
  }
  & > div {
    position: absolute;
    white-space: nowrap;
    display: flex;
    align-items: baseline;
    @media (min-width: 1366px) {
      margin-left: 10px;
      margin-top: 3px;
    }
  }

  @media (min-width: 768px) {
    padding: 32px;
    height: 114px;
  }

  @media (min-width: 1366px) {
    height: 122px;
  }

  @media (min-width: 1920px) {
    height: 138px;
  }

  ${({ isAnimated, currentWidth }) =>
    isAnimated &&
    css`
      width: ${currentWidth + 34}px;
      transition: 1s linear, opacity 0.7s linear 1s;
      opacity: 1;
    `}

  ${({ isAnimated, isHide }) =>
    isAnimated &&
    isHide &&
    css`
      width: 0 !important;
      opacity: 0;
      transition: 1s linear, opacity 0.7s linear 1s;

      & > div {
        opacity: 0;
        transition: 1s linear, opacity 0.7s linear 1s;
        transition: opacity 0.7s linear;
      }
    `}

  ${({ isAnimated, isSemiHide }) =>
    isAnimated &&
    isSemiHide &&
    css`
      & > div {
        opacity: 0;
        transition: opacity 0.7s linear;
      }
    `}
`;
